
.stats-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    //background-color: $white;
    margin: auto;
    text-align: center;

    //@include media('>=medium') {
        //padding: 20px;
    //}

    &__content {
        display: flex;
        justify-content: space-around;

        @include media('>=medium') {
            margin-bottom: 0;
        }
    }

    &__player-display {
        display: flex;
        justify-content: space-around;
        align-content: center;
        width: 50%;
        height: 100%;
        padding: 1em;

        @include media('>=medium') {
            padding: 3em;
        }

        .pager--left {
            transform: scale(-1); /* Flip pager arrow horizonally to create left arrow */
        }
    }

    &__pager {
        background-image: url("../img/svg/pager-arrow.svg");
        background-size: contain;
        background-position: center;
        position: relative;
        margin-top: 180px;
        min-width: 30px;
        height: 30px;
        z-index: 9;
        cursor: pointer;

        @include media('>=medium') {
            min-width: 40px;
            height: 40px;
        }
    }

    &__player-summary {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;

        @include media('>=medium') {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0;
            font-family: $font-family-heading;
        }
    }

    &__player-shirt {
        /*display: flex;
        justify-content: center;
        align-items: center;*/
        text-align: center;

        @include media('>=medium') {
            background-image: url('../img/svg/shirt.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 300px;
            min-width: 280px;
            padding-top: 100px;
        }
    }

    &__player-number {
        font-size: 3rem;
        font-weight: bold;
        width: 100%;

        @include media('>=medium') {
            font-size: 5rem;
        }
    }

    &__player-name {
        margin-top: 1%;
        font-size: 1em;
    }
    &__player-position {
        margin-top: 1%;
        font-size: 1em;
    }

    &__team-name {
        position: absolute;
        top: 115px;
        color: $brand-blue;

        @include media('>=medium') {
            top: 360px;
        }
    }

    &__stats-display {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 2em;
        border-top: 1px solid $grey-faint;
    }

    &__stats-summary {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        @include media('>=medium') {
            margin: 0 10%;
        }
    }

    &__val {
        flex-basis: 80px;
        font-size: 1.75rem;
        font-weight: bold;
    }
}