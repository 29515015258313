.column-container {
	border-bottom: 1px solid rgba(0,0,0, .2);
	.title-xl {
		font-family: $font-family-heading;
		font-size: 7rem;
		line-height: 1;
		@include media('<1440px'){
			font-size: 4rem;
		}
	}

	.col {
		&__content-title, &__content-text {
			font-size: 17px;
		}

		&__content-title {
			font-family: $font-family-heading;
		}

		&__content-text {
			+ .col__content-cta {
				margin-top: 2rem;
			}
		}
	}
}
