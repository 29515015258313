.game-hub {
    &__logo {
        order: 1;
    }

    &__filters {
        order: 2;

        @media (max-width: $bp-medium){
            order: 3;
        }

        button {
            @apply u-text-white u-border-white;
            transition: all .5s ease;

            &.active, &:hover {
                @apply u-bg-white u-text-navy;
            }

            @media (max-width: $bp-medium) {
                padding: .5rem 1rem;
            }
        }
    }

    &__actions {
        order: 3;
        @media (max-width: $bp-medium){
            order: 2;
        }
    }

    .ease {
        transition: all .5s ease;
    }
}

.mini-hub {
    max-height: 80vh;

    @media(max-width: $bp-small) {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px) !important;
    }

    @media(min-width: $bp-small) {
        position: absolute !important;
        top: 0;
        right: 0;
    }

    &__live-carousel {
        z-index: 999999;
        bottom: -60px;
        min-height: 60px;
    }
}

.side-hub {
    z-index: 999999999999;
}

.game-hub-item {
    &--overview {
        min-height: 70px;
    }
}

.game-hub-dropdown {
    &--team-name {
        max-width: calc(100% - 2.5rem);
    }
}