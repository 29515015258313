.poll-ring--winner {
	stroke: $brand-red;
	transform: rotate(-90deg);
	transform-origin: center;
}

.poll-ring--loser {
	stroke: $brand-blue;
}

.poll-bar {
	&--bg {
		color: $brand-blue;
		top: 0;
		left: 0;
		bottom: 0;
	}
}

.poll-button {
	&--half {
		width: calc(50% - 1px);
	}
}