.stats-wheel {
	&__wheel {
		max-width: 300px;
		@include media('>=medium') {
			max-width: 450px;
		}
	}
	&__arrow:focus {
		box-shadow: 0 0 0 0.2rem #bdbdff;
	}
}
