.top-scorer-card {
    &__player-image {
        background-size: cover;
        background-position: 50% 0%;
        background-repeat: no-repeat;
        height: 250px;
    }

    &__top-scorer-list {
        position: relative;
        width: 100%;
        background-color: $white;
        list-style-position: inside;
        padding-inline-start: 0;
    }

    &__list-item {
        position: relative;
        width: 100%;
        background-color: $white;
        border-bottom: 1px solid $grey-faint;
        padding: 1rem;
    }
}