.swiper-pagination-bullet {
	@apply .u-border .u-border-2 .u-h-3 .u-w-3 .u-bg-transparent .u-opacity-100;

	&.swiper-pagination-bullet-active {
		@apply .u-bg-white;
	}
}

.c-hero-carousel__item__blocker{
	position: absolute; top: 0; left: 0; right: 0; bottom: 32px;
}

.c-hero-carousel__item {
	// Reset the box-sizing as Swiper sets it to content-box on .swiper-wrapper and
	// Tailwind's Preflight sets elements to inherit box-sizing.
	box-sizing: border-box;

	// On small screens, fill the screen (minus the header height).
	// height: calc(100vh - 81px);

	//@screen md {
		// On larger screens we needn't give the carousel quite as much height.
		// height: calc(80vh - 70px);
	//}

	.vjs-big-play-button
	{
		display: none;
	}

	.play-button{
		position: relative;
		.pause{
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}

		&.playing{
			.pause{
				opacity: 1;
			}
			.play{
				opacity: 0;
			}
		}
	}
}

.swiper-slide:hover,
.swiper-slide:focus {
	svg {
		color: $brand-orange;
		transition: .7s;
	}
}

.swiper-container .swiper-slide {
	height: auto;

	@include media('>=large') {
		height: 70vh;
	}

	.c-hero-carousel__item {
		height: 100%;
		background-position: center;

		@include media('>=medium') {
			background-position: 0 0;
		}
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		background: transparent;
		background: -moz-linear-gradient(90deg, rgba(0,0,45,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(90deg, rgba(0,0,45,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
		background: linear-gradient(90deg, rgba(0,0,45,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00002d",endColorstr="#ffffff",GradientType=1);

		&--right {
			background: -moz-linear-gradient(270deg, rgba(0,0,45,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(270deg, rgba(0,0,45,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
			background: linear-gradient(270deg, rgba(0,0,45,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00002d",endColorstr="#ffffff",GradientType=1);
		}

		&--light {
			background: -moz-linear-gradient(90deg, rgba(255,255,255,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
			background: linear-gradient(90deg, rgba(255,255,255,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

			&.overlay--right {
				background: -moz-linear-gradient(90deg, rgba(255,255,255,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
				background: -webkit-linear-gradient(90deg, rgba(255,255,255,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
				background: linear-gradient(90deg, rgba(255,255,255,0.8015581232492998) 0%, rgba(255,255,255,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
			}
		}
	}
}

.match-highlight-carousel {
	.c-hero-carousel, .swiper-wrapper, .swiper-slide {
		min-height: 600px;
	}

	.swiper-container {
		.swiper-slide {
			.c-hero-carousel__item {
				@include media('>=medium') {
						background-position: 50% 30%;
				}
			}
		}
	}
}

