
.promo {
  display: flex;

  @include media('>=0', '<medium') {
    flex-direction: column;
  }

  @include media('>=medium') {
    font-size: 4.7rem;
  }
}

.promo__info {
  flex-grow: 1;
  color: $white;
  background-color: $brand-navy-dark;

  @include media('>=0', '<medium') {
    order: 2;
  }
}

.promo__info-inner {
  @include media('>=0', '<medium') {
    padding: 50px 15px;
  }

  @include media('>=medium') {
    width: 100%;
    max-width: calc(1285px / 2);
    padding: 50px 40px 110px 15px;
    margin-left: auto;
  }
}

.promo__title,
.promo__sub-title {
  line-height: 1.05;
  margin-top: 0;

  @include media('>=0', '<medium') {
    font-size: 2.5rem;
  }

  @include media('>=medium') {
    font-size: 2.7rem;
  }
}

.promo__title {
  padding-left: 18px;
  margin-bottom: 25px;
}

.promo__sub-title {
  display: inline-block;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 25px 0 0 18px;
  margin-bottom: 40px;
}

.promo__image {
  background-position: center;
  background-size: cover;

  @include media('>=0', '<medium') {
    height: 50vh;
    min-height: 350px;
    order: 1;
  }

  @include media('>=medium') {
    width: 50%;
  }
}

.event-info {
  list-style-type: none;
  font-weight: 800;
  padding: 0;
  margin: 0;

  @include media('>=0', '<medium') {
    font-size: 1.4rem;
  }

  @include media('>=medium') {
    font-size: 1.6rem;

    .--beach & {
      font-size: 1.9rem;
    }
  }

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
}

.event-info__label {
  white-space: nowrap;
  border: 1px solid currentColor;
  border-radius: 50px;
  padding: 5px 18px;
  margin-right: 20px;

  &--white {
  }
  &--clv-green {
    color : $champ-green;
  }
  &--ev-red {
    color : $brand-red;
  }
  &--ebv-orange {
    color: $beach-peach
  }
  &--cev-yellow {
    color : $color-supernova;
  }
  &--svb-blue {
    color: $snow-blue;
  }
}

.event-info__value {
  line-height: 1;
  padding: 6px 0;
  margin: 0;
}