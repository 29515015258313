.ranking-list {
    tbody tr:nth-child(odd) {
        background-color: $white;
    }

    th, td {
        padding: 0.75rem;
        font-weight: normal;
    }

    &__last-updated-text {
        min-width: 14rem;
    }

    &--blue-dash {
        color: $brand-blue;
        font-weight: bold !important;
    }

    &__flagIcon {
        .brand--champ & {
            background-color: transparent;
            border-radius: 0;
            border: none;
        }
    }
}