.watch-live-bar {
	@screen md {
		margin-top: calc((104px / 2) * -1);
	}

	&__inner {
		min-height: 104px;
	}

	&__logoWrapper {
		display: flex;

		& > * + * {
			margin-left: 1rem;
		}
	}

	&__logo {
		@include media('<=768px') {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.scorecentre__wrapper:not(.brand--champ) & {
			position: relative;
			overflow: hidden;
			border: 2px solid #bebebe;
			border-radius: 100%;
		}
	}

	&__logoImage {
		max-height: 100%;

		.scorecentre__wrapper:not(.brand--champ) & {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			object-fit: cover;
			background-color: $white;
			height: 66px;
		}
	}
}

.scorecentre {
	&__loading {
		height: 612px;

		@screen md {
			height: 572px;
		}
	}

	&__match {
	}

	&__matchItem {
		.scorecentre__wrapper:not(.brand--champ) & {
			position: relative;
			overflow: hidden;
			border: 4px solid $white;
			border-radius: 100%;

			width: 8rem !important;
			height: 8rem !important;
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}

	&__matchLogo {
		.scorecentre__wrapper:not(.brand--champ) & {
			position: absolute;
			top: -1px;
			right: 0;
			bottom: 0;
			left: 0;
			object-fit: cover;
			background-color: $white;
			width: 100%;
			height: 102%;
		}
	}

	&__article {
		@screen lg {
			min-height: 572px;
		}
	}
}