.primary-footer {
  svg {
    @apply u-block;
  }

  h5 {
		@apply u-my-8 u-text-2xl;

		@include media('>=medium') {
			@apply u-text-lg;
		}
  }
}

.primary-footer__links .div-columns__item {
	@include media('>=medium') {
		@apply u-px-5;
	}

	ul {
		@apply u-list-reset;

		li {
			@apply u-my-2;
		}
	}

	li a {
		@apply no-underline u-text-white u-text-base;

		@include media('>=medium') {
			@apply u-text-sm;
		}

		&:hover {
			@apply u-text-yellow;
			transition: $global-transition;
		}
	}
}

.primary-footer__logo {
	@apply u-inline-block u-text-white no-underline leading-none u-pr-5;
  font-family: $font-family-heading;
  font-size: $footer-logo-text-size;

	@include media('>=medium') {
		@apply u-pl-5 u-pr-0;
	}

	&:hover {
		color: $white;
	}
}

.primary-footer__social-item {
	@apply u-mr-3 u-w-auto u-h-4 u-text-white;

  @include media('>=medium') {
		@apply u-mr-5;

    &:hover {
			@apply u-text-yellow;
      transition: $global-transition;
    }
  }

  svg {
    fill: currentColor;
  }
}
