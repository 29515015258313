fieldset.form {
	padding: 1.5rem 0;

	select,
	input {
		border: 1px solid $grey-dark;
		background-color: $white;
		font-size: 1rem;
		color: $black;
		padding: 0.4rem;
	}

	input[type=checkbox] {
		margin-right: 1rem;
	}

	button{
		border: 1px solid $grey-dark;
		background-color: $white;
		color: $black;
		&:hover{
			background-color: $grey-light;
		}
	}

	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.fullWidth {
		label {
			display: block;
			width: 100%;
			margin-bottom: 0.5rem;
		}

		select,
		input {
			width: 100%;
			max-width: 100%;
		}
	}

	.asterisk {
		color: $brand-red;
	}

	li {
		margin-bottom: 1.5rem;

		&.nolabel{
			select,
			input{
				width: 100%;
				max-width: 20rem;
			}
		}

		&.buttons{
			button{
				padding: 0.5rem;
			}

			button + button{
				margin-left: 1rem;
			}
		}

		&.error{
			color: red;
		}
	}
}

#mc_embed_signup {
	div {
		&.mce_inline_error {
			padding: 5px 0 !important;
			background-color: white !important;
			font-weight: normal !important;
			color: $brand-red !important;
		}
	}
	input.mce_inline_error {
		border-color: $brand-red !important;
	}
}