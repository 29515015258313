// Import the Bootstrap grid.
@import "~bootstrap/scss/_grid.scss";

.container {
  max-width: $container-max-width;
}

.container--white {
  background-color: $white;
}

.container--padding-top {
  padding-top: 37px;
}
.container--padding-bottom {
  padding-bottom: 37px;
}

.section-padding {
  @include media('>=0', '<medium') {
    padding: 45px 0;
  }

  @include media('>=medium') {
    padding: 70px 0;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.u-breakout {
	position: relative;
	right: 50%;
	left: 50%;
	width: 100vw;
	margin-right: -50vw;
	margin-left: -50vw;
}

.hidden {
    display: none !important;
}

.u-gap-5 > .u-w-1\/4 {
  width: calc(25% - 1.25rem) !important;
}

.u-gap-5 > .md\:u-w-1\/4 {
    @media (min-width: 768px) {
      width: calc(25% - 1.25rem) !important;
  }
}

