.search {
    display: inline-block;
    position: relative;
    border: 1px solid $grey-light;

    @media only screen and (min-width: 768px) {
        margin: 0 0.75rem;
    }

    &__field-container {
        display: flex;
        justify-content: flex-end;

        .search-input {
            padding: 1em;
            padding-right: 50px;
        }

        .search-button {
            position: absolute;
            background: url('../img/icons/_x/Search.svg') 50% 50% no-repeat;
            height: 50px;
            width: 50px;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    }
}