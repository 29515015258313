.c-address-card {
	border-top: 2px solid $brand-orange;

	&__block{
		border-top: 1px solid $grey-faint;
	}

	&__img{
		border-right: 1px solid $grey-faint;
	}

	&__list{
		& + & {
			padding-top: 1rem !important;
		}

		li{
			margin-bottom: 0.5rem;
			svg{
				transform: translateY(3px);
				margin-right: 0.8rem;
			}
		}
	}

	.blockTitle{
		display: flex;
		align-items: center;

		@include media('>small') {
            h3{
                width: 50%;
            }
		}

		@include media('>large') {
            h3{
                width: 40%;
            }
		}
    }
}