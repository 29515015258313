@media (prefers-reduced-motion: reduce) {
	* {
		transition-duration: 0.001s !important;
		animation-duration: 0.001s !important;
	}
}

.animation-blink {
	animation: 3s ease-in-out infinite blink;
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes spin {
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

.animation-spin {
	animation: spin 2s linear infinite;
}

@keyframes shake-2px {
	from,
	to {
		transform: translate3d(0, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-2px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
}

.animation-shake-2px {
	animation: shake-2px 1s linear none;
}
