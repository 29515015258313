.carousel-component {
    @extend %pop-out-full-width;

  .lg\:u-w-3\/4 & {
    @extend %pop-out-full-width-from-two-column;
  }

  &__video-slide {
    .video-playing {
      display: none;
    }
  }

  .carousel-image-min-height {
    @media (min-width: $bp-small) {
      min-height: 12.5rem;
    }
    @media (min-width: $bp-medium) {
      min-height: 22.5rem;
    }
    @media (min-width: $bp-large) {
      min-height: 25rem;
    }
  }
}
