.shirt {
	max-width: 200px;
	&--middle {
		margin-top: -3rem;
	}
	&--playbyplay {
		max-width: 50px;
		min-height: 1px; /* Fixes IE flex column bug */
	}
	&--featured {
		max-width: 150px;
	}
	&__name {
		word-break: break-word;
	}
	@include media('≥medium') {
		&__name {
			margin-left: 14%;
		}
	}

	&-1 {
		top: 0;
		left: 0;
	}
	&-2 {
		bottom: 0;
		right: 0;
	}
}
