.hero {
    position: relative;
    z-index: 2;
    height: calc(100vh - 70px); // 100% - header;
    min-height: 650px;

    @include media('>=0', '<medium') {
      background: $brand-navy no-repeat center;
      background-size: cover;
    }

    @include media('>=medium') {
      display: flex;
      align-items: stretch;
    }

    &__cta {
        width: 50%;

        @include media('>=0', '<medium') {
            width: 100%;
        }
    }
  }

  .hero__panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;

    @include media('>=0', '<medium') {
      width: 100%;
      height: 50%;
      background-size: 100%;
    }

    @include media('>=medium') {
      width: 100%;
      height: 100%;
      background-color: $brand-navy;
      background-size: 120%;
    }

    &:first-child {
      border-right: 1px solid $grey-light;
    }

    .hero__image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display:none;

        @include media('>=0', '<medium') {
            display:block;
          }
    }

    &.hero__panel--mens {
      @include media('>=medium') {
        .hero__image {
            background-position: center center;
        }
        &:hover {
            .hero__image {
                display:block;
            }
        }
      }
    }

    &.hero__panel--womens {
      @include media('>=medium') {
          .hero__image {
            background-position: center center;
          }
        &:hover {
            .hero__image {
                display:block;
            }
        }
      }
    }

    .gender-nav__tab {
      @include media('>=0', '<medium') {
        display: none;
      }

      @include media('>=medium') {
        height: 160px;
      }
    }
  }

  .hero__emblem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include media('>=0', '<medium') {
      display: none;
    }

    .hero__panel:hover & {
      display: none;
    }
  }

  .hero-info {
    width: 100%;
    color: $white;
    background-repeat: no-repeat;

    @include media('>=0', '<medium') {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background-size: 75%;
      padding: 20px;
    }

    @include media('>=medium') {
      display: none;
      max-width: calc(1285px / 2); // .container width minus padding and halved
      background-size: 80%;
      padding-left: 15px;
    }

    .hero__panel:hover & {
      @include media('>=medium') {
        display: block;
      }
    }

    .hero__panel--mens & {
      background-position: bottom right;

      @include media('>=0', '<medium') {
      }

      @include media('>=medium') {
        margin-left: auto;
      }
    }

    .hero__panel--womens & {
      background-position: 100% 100%;

      @include media('>=0', '<medium') {
      }

      @include media('>=medium') {
        padding-left: 72px;
        margin-right: auto;
      }
    }
  }

  .hero__title {
    line-height: 0.9;

    @include media('>=0', '<medium') {
      font-size: 3.7rem;
      margin: 0 0 20px;
    }

    @include media('>=medium') {
        font-size: 4.7rem;
        margin: 70px 0 30px;
    }
  }

  .hero__ticket-btn {
    @include media('>=0', '<medium') {
      margin: 20px 0;
    }

    @include media('>=medium') {
      margin: 30px 0;
    }
  }

  .hero__sub-title {
    line-height: 0.9;
    margin: 0;
    font-size: 4.7rem;

    @include media('>=0', '<medium') {
        font-size: 3.7rem;
      }
  }

.gender-nav {
    display: flex;
  }

  .gender-nav__tab {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 160px;
    cursor: pointer;
    background-color: $white;

    &:not(.is-active):hover,
    .hero__panel:hover &:not(.is-active) {
      color: $brand-red;
      background-color: currentColor;
    }

    &.is-active {
      color: $grey-light;
      background-color: currentColor;

      &:hover {
        cursor: default;
      }
    }

    &:hover,
    .hero__panel:hover &,
    &.is-active {
      @include media('>=medium') {
        &:after {
          content: '';
          position: absolute;
          bottom: -23px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 23px 25px 0 25px;
          border-color: currentColor transparent transparent transparent;
        }
      }
    }
  }

  .gender-nav__tab-inner {
    width: 100%;
    max-width: calc(1285px / 2); // .container width minus padding and halved
    color: $black;
    padding-left: 15px;

    @include media('>=0', '<medium') {
      padding: 15px;
    }

    .gender-nav__tab--mens & {
      margin-left: auto;
    }

    .gender-nav__tab--womens & {
      @include media('>=medium') {
        padding-left: 72px;
        margin-right: auto;
      }
    }
  }

  .gender-nav__tab-title {
    font-size: 2.5rem;
    line-height: 1;
    color: $black;
    margin: 0;

    .gender-nav__tab:not(.is-active):hover &,
    .hero__panel:hover .gender-nav__tab:not(.is-active) & {
      color: $white;
    }
  }

  .gender-nav__tab-btn {
    font-family: $font-family-heading;
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 10px;

    &:hover {
      color: $white;
      background-color: $brand-navy-dark;
    }
  }

.selector-component {
  z-index: 0 !important; // Bugfix - positions the selector component behind the nav drop-down panel //
}