.final-rankings-container {
    table {
        thead {
            tr {
                border-bottom: 2px solid #DEDBDB;
            }
        }
    }

    th {
        padding: 1em;
        min-width: 3em;
        text-align: left;
    }

    td {
        padding: 1em;

        &.country {
            min-width: 10em;
        }
    }

    &__flagWrapper {
        position: relative;
        display: inline-block;
        float: left;
        margin-top: -3px;
        margin-right: 0.5em;

        .competition-components-container:not(.brand--champ) & {
            width: 1.5em;
            height: 1.5em;
            background-color: #f4f4f4;
            border: 1px solid $grey-light;
            border-radius: 50%;
            overflow: hidden;
        }

        .competition-components-container.brand--champ & {
            max-height: 1.5em;
        }
    }

    &__flagImage {
        .competition-components-container:not(.brand--champ) & {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            max-width: 100%;
        }

        & > img {
            .competition-components-container:not(.brand--champ) & {
                height: 100%;
                width: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                object-position: center;
            }

            .competition-components-container.brand--champ & {
                height: 100%;
                max-height: 1.5em;
            }
        }
    }

    &__highlight {
        background-color: $grey-light;
    }
}

