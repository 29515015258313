// We're using this class because we don't want to retroactively potentially destroy existing table styles
// So we want to make sure to add .table to new tables and we can go back and edit old ones as needed
.table {
    width: 100%;
    max-width: 100%;
    margin: 10px 0;

    &--pad-first {
        tr {
            td:first-child,
            th:first-child {
                padding-left: 1rem;
            }
        }
    }

    &--wrapper {
        overflow-x: auto;
        max-width: 90vw;

        &.full-width-component {
            max-width: 100vw;
        }
    }

    thead tr:first-child {
        border-bottom: 2px solid #DEDBDB;
    }

    th {
        padding: 1em 0.5em;
        min-width: 2.5em;
        text-align: left;
    }

    td {
        padding: 1em 0.5em;
        text-align: left;
    }

    &--striped {
        tr:nth-child(odd) {
            background-color: white;
        }
        thead {
            tr:nth-child(odd) {
                background-color: transparent;
            }
        }
    }

    .display-state {
        display: none;
    }

    /* Display classes showing pool data on tab click */

    .hidden {
        display: none;
    }

    .show {
        visibility: visible;
    }

    @media only screen and (min-width: 1000px) {
        .display-state {
            display: table-cell;
        }
    }

    &--mobile {
        @media only screen and (max-width: 768px)  {
            display: block;

            /* Force table to not be like tables anymore */
            thead, tbody, th, td, tr {
                display: block;
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr { border: 1px solid #ccc; }

            td {
                /* Behave  like a "row" */
                border: none;
                border-bottom: 1px solid #eee;
                position: relative;
                padding-left: 50%;
                text-align: left !important;
            }

            td:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 1rem;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                font-weight: bold;
                text-align: left;
            }
        }
    }

    &--beach-snow {
        @media only screen and (max-width: 768px)  {
            td:before {
                top: 50%;
                margin-top: -.5em;
            }
            td:nth-of-type(1):before { content: "Seed"; }
            td:nth-of-type(2):before { content: "Country"; }

            .u-w-1\/2 {
                width: 44% !important;
            }
        }
    }
}