.double-donut {
	width: 100%;
	padding-bottom: 100%;
	&__bg {
		stroke: $grey-light;
	}
	&__ring {
		transform: rotate(-90deg);
		transform-origin: center;
		transition: 1s;
		&--away {
			stroke: $brand-red;
			transition-delay: 0.2s;
		}
		&--home {
			stroke: $brand-blue;
			transform: rotate(-90deg) scaleY(-1);
		}
	}
}
