
.sponsors-list {
	margin: 0;
	padding: 0;
	list-style: none;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;

	> * {
		margin: 0 1em;
	}
}