.content-card--flexible-cta {
	@media(min-width: 768px){
		min-height: 412px;
	}
}

.toi-container {
	display: flex;
	padding: 1em;

	&.toi-container--top {
		align-items: flex-start;
	}
	&.toi-container--mid {
		align-items: center;
	}
	&.toi-container--bottom {
		align-items: flex-end;
	}

	&.toi-container--left {
		text-align: left;
    justify-content: flex-start;
	}
	&.toi-container--center {
		text-align: center;
    justify-content: center;
	}
	&.toi-container--right {
		text-align: right;
    justify-content: flex-end;
	}

	// &.toi-container--tl {
	// 	align-items: flex-start;
  //   justify-content: flex-start;
	// }
	// &.toi-container--tc {
	// 	align-items: flex-start;
  //   justify-content: center;
	// }
	// &.toi-container--tr {
	// 	align-items: flex-start;
  //   justify-content: flex-end;
	// }

	// &.toi-container--ml {
	// 	align-items: center;
  //   justify-content: flex-start;
	// }
	// &.toi-container--mc {
	// 	align-items: center;
  //   justify-content: center;
	// }
	// &.toi-container--mr {
	// 	align-items: center;
  //   justify-content: flex-end;
	// }

	// &.toi-container--bl {
	// 	align-items: flex-end;
  //   justify-content: flex-start;
	// }
	// &.toi-container--bc {
	// 	align-items: flex-end;
  //   justify-content: center;
	// }
	// &.toi-container--br {
	// 	align-items: flex-end;
  //   justify-content: flex-end;
	// }
}

// .toi-container--tl {
// 	text-align: left;
// }
// .toi-container--tc {
// 	text-align: center;
// }
// .toi-container--tr {
// 	text-align: right;
// }

// .toi-container--ml {
// 	text-align: left;
// }
// .toi-container--mc {
// 	text-align: center;
// }
// .toi-container--mr {
// 	text-align: right;
// }

.content-card--full {
	a {
		top: 0;
	}
}

.content-card__image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-card__video {
	> div {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	iframe {
		width: 100%;
		height: 100%;
	}
}
