
.article {
	h4 {
		color: $champ-green;
		font-weight: 300;
	}

	&__header,
  	&__content,
  	&__footer {
	    padding: 2em 6%;
	}

	&__header {
		padding-top: 2em;
		padding-bottom: 2em;
		border-bottom: 1px solid $grey-light;
	}

	&__heading,
	&__sub-heading {
		font-size: 50px;
		line-height: 1.2;
	}
	&__heading {
		margin: 0;
	}
	&__sub-heading {
		margin: 0 0 1em;
	}

	&__meta {
		h4 {
			margin: 0 0.6em 0 0;
			float: left;
			color: $champ-green;
			font-weight: 300;
		}
		span {
			color: $grey-light;
		}
	}

	&__content {
		p {
			font-size: 1.3rem;
		}
	}

	&__footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
}