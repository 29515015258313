.star-player-banner {
	display: flex;
	background: $brand-navy;

	@media(max-width: 520px) {
		display: block;
	}

	.star-player-quick-info {
		padding: 32px;
		color: #fff;
		width: 100%;

		.star-player-subinfo {
			display: flex;
			padding: 0;

			span {
				padding: 0 5px;
			}
		}
	}

	.star-player-img {
		display: flex;
		margin-left: auto;
		padding: 0 2rem;
		padding-top: 2rem;
		place-self: flex-end;
		height: 300px;

		img {
			height: 100%;
			object-fit: contain;
			object-position: bottom;
		}
	}
}

.star-player-info {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 -.25rem;

	&--with-header {
		height: calc(100% - 83px);
	}

	.star-player-info-block {
		display: inline-block;
		width: 25%;
		padding: 0 .25rem;

		@media (max-width: 1025px) {
			width: 50%;
		}

		label {
			font-size: 5rem;
			font-weight: 800;
		}
	}
}

.star-player-info-section {
	max-width: 50%;

	@media(max-width: 1025px){
		max-width: 100%;
	}
}

.star-player-nationality-flag {
	border-radius: 100%;
	overflow: hidden;
	display: inline-block;
	width: 80px;
	height: 80px;
	border: 5px solid #dadada;
	margin: 3rem 0;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		transform: scale(1.1);
	}
}

.star-player-info-wrapper {
	display: flex;
	margin-top: 1rem;

	.player-data {
		margin-right: 3rem;
	}
}

.star-player-quick-info-wrapper {
	margin: 0 -.5rem;

	@media(min-width: 450px) {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
	}

	> * {
		width: 25%;
		padding: 0 .5rem;
	}
}

// Img Layout

.silhouette-layout {
	align-items: flex-start;

	.silhouette-container {
		width: 50%;
		margin-left: -7%;

		@media (Max-width: 1025px) {
			margin-left: auto;
			margin-right: auto;
			margin-top: 3rem;
			width: 100%;
		}
	}

	.player-stats {
		margin-top: 3rem;

		.star-player-info-block {
			text-align: left;
		}
	}
}
