.tab-container {
    display: flex;
    flex-direction: column;

    .pool-tab {
        height: auto;
        margin: 0.25em 0.75em;
        padding: 9px 40px;
        border: 1px solid $grey-light;
        outline: none;
        text-align: center;
        cursor: pointer;

        //&.is-active {
            //color: $brand-orange;
        //}
    }

    &--center {
        justify-content: center;
    }
}

.pool-standings-table {
    margin: 10px 0;

    table {
        thead tr:first-child {
            border-bottom: 2px solid #DEDBDB;
        }
    }

    th {
        padding: 1em 0.2em;
        min-width: 2.5em;
        text-align: left;
    }

    td {
        padding: 1em 0.5em;
        text-align: left;

        &.country {
            min-width: 10em;
        }

        .flag-icon {
            position: relative;
            display: inline-block;
            float: left;
            width: 1.5em;
            height: 1.5em;
            margin-top: -3px;
            margin-right: 0.5em;
            background-color: #f4f4f4;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border: 1px solid $grey-light;
            border-radius: 50%;
        }
    }

    &--striped {
        tr:nth-child(odd) {
            background-color: white;
        }
        thead {
            tr:nth-child(odd) {
                background-color: transparent;
            }
        }
    }

    &__flagIcon {
        position: relative;
        display: inline-block;
        float: left;
        width: 1.5em;
        height: 1.5em;
        margin-top: -3px;
        margin-right: 0.5em;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #f4f4f4;
        border: 1px solid $grey-light;
        border-radius: 50%;

        .brand--champ & {
            background-color: transparent;
            border: none;
            border-radius: 0;
        }
    }

    .display-state {
        display: none;
    }

    /* Display classes showing pool data on tab click */

    .hidden {
        display: none !important;
    }

    .show {
        visibility: visible;
    }

    @media only screen and (min-width: 600px) {
        .tab-container {
            flex-direction: row;
        }
    }
    @media only screen and (min-width: 1000px) {
        .display-state {
            display: table-cell;
        }
    }
}

@media only screen and (min-width: 600px) {
    .tab-container {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-around;

        .pool-tab {
            margin: 0.25em 0;
            margin-right: 0.5em;
        }
    }

    .display-state {
        display: table-cell;
    }
}

.overlay-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.7em;
    left: 0;
    width: 100%;
    height: 4em;
    font-size: 1.25em;
    background-color: rgba(255,255,255,0.95);
    padding: 0.5em 1em;
    transform-origin: left;
    z-index: 9;

    @media only screen and (min-width: 520px) {
        display: none;
    }

    img {
        width: 1.5em;
        height: auto;
        margin-bottom: -0.25em;
    }
}

.fade {
    animation: fade-out 0.3s ease-out forwards;
}

@keyframes fade-out {
    0% {
        transform: scaleX(1);
        opacity: 1;
    }
    100% {
        transform: scaleX(0);
        opacity: 0;
    }
}

