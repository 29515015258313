.manual-event {
    &__item {
        @media(min-width:768px){
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }

    &__image {
        @media(min-width:768px){
            grid-column-start: 1;
            grid-column: span 2 / span 2;
        }
    }

    &__date {
        @media(min-width:768px){
            grid-column-start: 3;
            grid-column: span 2 / span 2;
        }
    }

    &__description {
        @media(min-width:768px){
            grid-column-start: 5;
            grid-column: span 6 / span 6;
        }
    }

    &__details {
        @media(min-width:768px){
            grid-column-start: 10;
            grid-column: span 2 / span 2;
        }
    }

    &__details, a {
        color: $color-supernova;
    }
}