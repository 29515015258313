.c-match-summary{
	@apply u-py-4 u-px-4 u-mt-4;
	display: flex;
    justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;

	> div {
		text-align: center;
		width: 100%;
	}

	@include media('>=small') {
		@apply u-py-4 u-px-8 u-mt-4;
		flex-direction: row;

		> div{
			text-align: left;
		}
	}

	@include media('<=medium'){
		flex-wrap: wrap;
	}

	&__section-header {
		@apply u-mb-2;
		color: $grey-dark;
	}

	&__location{
		order: 3;
		P {
			@apply u-text-base;
		}

		color: $grey-dark;

		@include media('>=small') {
			padding-top: 0;
			width: 50% !important;
			text-align: right !important;
		}

		@include media('>medium') {
			width: 33% !important;
		}
	}

	&__overview{
		order: 2;
		@include media('>=small') {
			width: 50% !important;
		}
		@include media('>medium') {
			width: 25% !important;
			order: 1;
		}
		P {
			@apply u-text-base u-mb-2;
			span {
				display: block;
			}
		}

		&__date{
			color: $grey-dark;
		}

		&__match{
			font-weight: bold;
			margin-right: 0.5em;
		}

		&__official-label {
			display: none;
			@include media('>=medium') {
				display: block;
			}
		}

		&__inline{
			display: block;

			@include media('>=huge') {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&__official{
		display: none;
		padding: 0.25em 0;

		@include media('>=small') {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: right;
			color: $grey-dark;
			width: 50%;
			float: right;
			clear: both;
		}

		.img{
			background-size: cover;
			background-repeat: no-repeat;
			display: block;
			min-width: 30px;
			height: 30px;
			border-radius: 50%;
			background-position: 50% 50%;
			border: 2px solid $grey-light;
			margin-right: 0.5rem;
		}
	}

	&__buttons {
		@apply u-pt-4;
		clear: both;
	}

	&__teamSummary{
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		width: 25%;
	}

	&__teamCode {
		@apply u-font-bold;
	}

	&__teamImage {
		background-size: 152%;
		background-repeat: no-repeat;
		display: block;
		width: 50px;
		height: 50px;
		background-position: 50% 50%;
		margin-bottom: 1rem;
		border-radius: 50%;
		border: 2px solid $grey-light;

		.brand--champ & {
			background-size: 100%;
			border-radius: 0;
			border: none;
		}
	}

	&__scorecentre{
		text-align: center !important;
		width: 100% !important;
		order: 1;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@include media('<=medium') {
			@apply u-pb-4 u-mb-4 u-border-b u-border-grey-lightest;
		}

		@include media('>medium') {
			width: 42% !important;
			order: 2;
		}

		>div{
			width: 33%;
		}

		p {
			@include media('<=medium') {
				@apply u-text-base;
			}
		}
	}

	&__goldenSet {
		@apply u-text-base u-text-yellow;
		strong {
			display: block;
		}
	}

	&__score{
		margin: 1em;
		text-align: center;
		width: 50%;

		.default{
			font-size: 2rem;
			font-weight: bold;
			margin-bottom: 2rem;

			@include media('>=medium') {
				font-size: 3rem;
			}
		}

		h2{
			font-size: 1.5rem;
			margin-bottom: 0.75rem;

			@include media('>=small') {
				font-size: 2.25rem;
			}

			@include media('>=large') {
				font-size: 2.5rem;
				margin-bottom: 1rem;
			}
		}
		p{
			color: $grey-dark;
		}

		span{
			white-space: nowrap;
		}
	}
	@include media('>=medium') {
		&__awayteam {
			order: 1;
		}
	}

	&__logoWrapper {
		position: relative;
		overflow: hidden;
	}

	&__logoImage {
		background-color: $white;
		display: block;
		margin: 0 auto;

		.brand--champ & {
			background-color: transparent;
			object-fit: cover;
		}
	}
}
