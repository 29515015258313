.match-gallery {
    /* Flex Fallback for IE */
    display: flex;
    flex-wrap: wrap;

    /* Supports Grid */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: minmax(150px, auto);
    grid-gap: 1em;

    @include media('>=medium') {
        grid-template-columns: repeat(4, 1fr);
    }

    a {
        /* Flex Fallback for IE */
        margin: 0.25rem;
        flex: 1 1 24%;
        height: auto;

        @supports (display: grid) {
            margin: 0;
      }
    }

    div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}

.match-img-summary {
    color: $grey-light;
}

.mfp-bottom-bar {
    padding: 1rem 0 !important;
  }

button.mfp-arrow {
    top: 8%;
    width: 5%;
}

.img-push {
    height: 75px;
}

.thumbnail-gallery {
    display: none;
    white-space: nowrap;
    overflow-x: auto;
    position: fixed;
    z-index: 2000;
    bottom: 30px;
    left: 15px;
    right: 47px;
    width: calc(100% - 62px);
    padding-right: 0;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    button {
        line-height: 0;
        border: 2px solid transparent;

        &.active {
            border: 2px solid $brand-orange;
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }
}