.video {}

.video__header {
  text-align: center;
  padding: 37px 0;
}

.video__title {
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 0;

  @include media('>=medium') {
    font-size: 2.5rem;
  }
  @include media('>=large') {
    font-size: 3.5rem;
  }
}

.video__wrapper {
  position: relative;
}

.video__overlay {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video__overlay-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media('>=0', '<medium') {
    width: 100%;
  }

  @include media('>=medium') {
    width: 50%;
  }
}

.video__sub-title {
  line-height: 1.1;
  color: $white;
  margin: 0 20px 0;
  font-size: 1.5rem;

  @include media('>=medium') {
    font-size: 2.5rem;
  }
  @include media('>=large') {
    font-size: 3rem;
  }
}

.video__bg-image {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
}

.video__play-btn {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-weight: 800;
  font-family: $font-family-heading;
  font-size: 1rem;
  color: $white;
  background-color: transparent;
  border-radius: 50%;

  @include media('>=0', '<medium') {
    width: 84px;
    height: 84px;
    //font-size: 2rem;
    border: 1px solid $white;
  }

  @include media('>=medium') {
    width: 144px;
    height: 144px;
    border: 3px solid $white;
    margin-right: -72px;
    font-size: 1.5rem;
  }

  //@include media('>=medium', '<large') {
    //font-size: 2rem;
  //}

  //@include media('>=large') {
    //font-size: 3rem;
  //}

  &:hover {
    background-color: $white;
    color: $brand-red;

    .--beach & {
      color: $beach-peach;
    }

    .--champ & {
      color: $champ-green;
    }
  }
}

.full-width-cta-component,
.full-width-component {
  @extend %pop-out-full-width;

  .lg\:u-w-3\/4 & {
    @extend %pop-out-full-width-from-two-column;
  }
}

