.c-timeline-carousel__item__blocker{
	position: absolute; top: 0; left: 0; right: 0; bottom: 32px;
}

.c-timeline-carousel__item {
	// Reset the box-sizing as Swiper sets it to content-box on .swiper-wrapper and
	// Tailwind's Preflight sets elements to inherit box-sizing.
	box-sizing: border-box;

	// On small screens, fill the screen (minus the header height).
	// height: calc(100vh - 81px);

	//@screen md {
		// On larger screens we needn't give the carousel quite as much height.
		// height: calc(80vh - 70px);
	//}
}

.swiper-slide:hover,
.swiper-slide:focus {
	svg {
		color: $brand-orange;
		transition: .7s;
	}
}

.swiper-container .swiper-slide {
	height: auto;

	@include media('>=medium') {
		height: 70vh;
	}

	.c-timeline-carousel__item {
		height: 100%;
		background-position: center;

		@include media('>=medium') {
			background-position: 0 0;
		}
	}
}

.c-carousel-square {
	.swiper-slide {
		@include media('>=medium') {
			height: auto;
		}
	}
}

.u-swiper-btn {
    color: $yellow;
}

