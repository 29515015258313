.pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
      border: 1px solid $grey-light;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-button {
      background-color: $white;
      color: $black;
      padding: 12px 16px;
      transition: background-color .3s ease;

      &--active {
        background-color: $brand-navy;
        color: $white;
      }

      &--page {
        @include media('<=medium') {
          display: none;
        }
      }

      &--previous {
        border-right: 1px solid $grey-light;
          @include media('<=medium') {
            border-right: none;
          }
      }

      &--next {
        border-left: 1px solid $grey-light;
      }

      &:disabled {
        opacity: .4;
        pointer-events: none;
      }

      &:hover {
        &:not(.pagination-button--active) {
          background-color: #ddd;
        }
      }
    }

	  //.pagination a:hover:not(.active),
	  //.pagination a:hover:not(.disable) {background-color: #ddd;}
}