.team-listing {
    &__image {
        background-color: $brand-navy;

        .team-listing__card--white & {
            background-color: $white;
        }
    }

    &__logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        object-fit: contain;
    }

    &__summary {
        border-top: 1px solid;

        .team-listing__card:not(.--is-active) & {
            border-color: transparent;
        }
    }
}