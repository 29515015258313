.team-profile {
	&--flag {
		display: inline-block;
		vertical-align: middle;
		max-height: 1.5rem;
		padding-left: 1rem;
	}
	.player-profile {
		display: flex;
		height: 380px;
		margin-bottom: 2rem;

		.player-img {
			img {
				height: 380px;
				width: 100%;
				object-fit: cover;
				object-position: top;
			}
		}

		.player-wrapper {
			display: flex;
			flex-direction: column;

			ul {
				list-style: none;
				padding: 0;
				margin-top: 2rem;
			}

			.player-number {
				height: 90px;
				width: 90px;
				background: $brand-navy-dark;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.player-info {
				margin-top: 2rem;
			}

			.hr-border {
				padding-top: 2rem;
			}

			.pos-bottom {
				margin-top: auto;
			}
		}
	}

	.team-profile-cards {
		display: flex;
		flex-wrap: wrap;

		.player-card {
			@apply u-mb-8;

			.player-img {
				width: 100%;
				height: 375px;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
					background: $brand-navy-dark;
					object-position: top;
				}
			}

			.player-label {
				background: #fff;
				display: flex;

				.player-number {
					padding: 10px;
					color: $color-supernova;
					font-weight: 600;
				}

				.player-name {
					padding: 10px 0;
					text-align: center;
					width: 100%;
				}
			}
		}
	}

	.team-stats {
		background: #fff;
		padding: 2rem;

		ul {
			display: flex;
			list-style: none;
			flex-wrap: wrap;
			padding: 0;
			justify-content: space-around;

			li {
				div {
					text-align: center;
				}
			}
		}
	}
}

.team-schedule {
	img {
		max-width: 50px;
	}
}

.brand--cev {
	.hr-border {
		border-color: $color-supernova;
	}
}

.brand--euro {
	.hr-border {
		border-color: $brand-red;
	}
}

.brand--beach {
	.hr-border {
		border-color: $beach-peach;
	}
}

.brand--snow {
	.hr-border {
		border-color: $snow-blue;
	}
}

.brand--olympic {
	.hr-border {
		border-color: $color-olympic;
	}
}

.brand--champ {
	.hr-border {
		border-color: $champ-green;
	}
}

.brand--beachroadtotokyo {
	.hr-border {
		border-color: $color-beachroadtotokyo;
	}
}

.brand--superfinals {
	.hr-border {
		border-color: $color-superfinals;
	}
}

.brand--opencampus {
	.hr-border {
		border-color: $color-opencampus;
	}
}

.brand--general {
	.hr-border {
		border-color: $brand-blue;
	}
}

.brand--indoor {
	.hr-border {
		border-color: $champ-green;
	}
}
