.c-calendar,
.c-calendar-sports {
	.swiper-container {
		.swiper-slide {
			&.brand-bg {
				color: inherit;
			}
			height: auto;
		}
	}
}

.c-calendar {
	&__slide {
		@include media('<medium') {
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}
		}

		@include media('>=medium') {
			display: grid;
			grid-template-columns: repeat(7, 4rem);
			grid-auto-rows: min-content;
			grid-gap: 8px;
			margin: 0 auto;
			justify-content: center;
		}

		@include media('>=large') {
			grid-template-columns: repeat(7, 6rem);
		}
	}

	.swiper-container-autoheight .swiper-wrapper {
		align-items: center;
	}

	&__heading {
		@apply u-p-4 u-text-center;
		grid-column: 1 /span 7;
	}
}

.c-calendar-wrapper--sidebar {
	display: block;
	width: 100%;

	@include media('>=large') {
		display: flex;
	}

	.c-calendar-wrapper__col-summary {
		@include media('>=large') {
			width: calc(100% - 300px);
		}
	}
}

.vdatetime-popup {
	&__header {
		background-color: $brand-blue !important;
	}

	&__actions__button {
		color: $brand-blue !important;
	}
}

.vdatetime-calendar {
	&__month__day {
		&--selected {
			>span {
				>span {
					background-color: $brand-blue !important;
				}
			}
		}
	}
}
