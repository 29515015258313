.team-compare-popup {
    top: 30vh;
    width: 60vw;
    left: 50%;
    margin-left: -30vw;

    @media(max-width: 768px) {
        top: 10vh;
        width: 90vw;
        margin-left: -45vw;
    }

    li {
        width: 25%;
    }
}