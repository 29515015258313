.article-listing-slide {
	box-sizing: border-box;
	height: auto;
	width: calc(100% - 20%);

	@include media('>=0', '<medium') {
		width: auto;
	}

	&--flexible-cta {
		width: 630px;
		max-width: calc((100vw - 30px));
	}

	&--basic {
		width: 300px;
		height: auto;
	}
}

.article-listing {
	&__swipe-prompt {
		background-color: rgba(0, 0, 0, 0.8);
	}

	&--mobile {
		position: relative !important;
		.swiper-pagination-bullet {
			box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
			border-color: $brand-blue;
			&.swiper-pagination-bullet-active {
				background-color: $brand-blue;
			}
		}
	}

	&--mini-standard {
		.article-listing--standard__column {
			min-height: 200px;
		}
	}

	&--standard {
		.col-4 {
			.article-listing--standard__column {
			   max-width: 400px;
			   min-height: 500px;
		   }
	   }

	   &__column {
		   max-width: 300px;
		   min-height: 400px;
	   }
	}

	&--video {
		.col-4 {
			.article-listing--video__column {
			   min-height: 0;
		   }
	   }

	   &__column {
			min-height: 0;
		}
	}
}

.c-article-listing-summary{
	$root: &;

	&__summary{
		border-top: 1px solid #fff;
	}

	&.--is-active{
		#{$root}__summary{
			border-top: 1px solid $brand-orange;
		}
	}
}

