.container.u-flex.u-flex-col {
	.u-w-full {
		.competition-components-container {
			.container {
				.u-breakout {
					position: unset;
					margin: 0;
					left: unset;
					right: unset;
					width: auto;
				}
			}
		}
	}
}
