.c-pool-list{
	$root: &;
	$spaceBetween: 1.5rem;

	&__items{
		display: flex;
		flex-wrap: wrap;

		&--3{
			@include media('>large') {
				#{$root}__item{
					width: calc((100% / 3) - (#{$spaceBetween} - (#{$spaceBetween} / 3)));
					&:nth-child(2n)
					{
						margin-right: $spaceBetween;
					}

					&:nth-child(3n){
						margin-right: 0;
					}
				}
			}
		}

		&--2{
			@include media('>medium') {
				#{$root}__item{
					width: calc((100% / 2) - (#{$spaceBetween} - (#{$spaceBetween} / 2)));

					&:nth-child(2n){
						margin-right: 0;
					}
				}
			}
		}
	}

	&__item{
		overflow: hidden;
		margin-bottom: $spaceBetween;
		background-color: $grey-light;

		h3{
			padding: 1rem;
		}

		ul{
			list-style: none;
			margin: 0;
			padding: 0;

			li{
				padding: 1rem;
				display: flex;
				align-items: center;

				img{
					margin-right: 0.5rem;
					max-height: 1.5rem;
				}
			}

			li + li {
				border-top: 1px solid $grey-dark;
			}
		}

		//size
		margin-right: $spaceBetween;

		width: calc((100% / 1) - (#{$spaceBetween} - (#{$spaceBetween} / 1)));
		&:nth-child(1n){
			margin-right: 0;
		}
	}

	@include media('>small') {
		&__item{
			width: calc((100% / 2) - (#{$spaceBetween} - (#{$spaceBetween} / 2)));
			&:nth-child(1n){
				margin-right: $spaceBetween;
			}
			&:nth-child(2n){
				margin-right: 0;
			}
		}
	}

	@include media('>medium') {
		&__item{
			width: calc((100% / 3) - (#{$spaceBetween} - (#{$spaceBetween} / 3)));
			&:nth-child(2n){
				margin-right: $spaceBetween;
			}
			&:nth-child(3n){
				margin-right: 0;
			}
		}
	}

	@include media('>large') {
		&__item{
			width: calc((100% / 4) - (#{$spaceBetween} - (#{$spaceBetween} / 4)));
			&:nth-child(2n),
			&:nth-child(3n)
			{
				margin-right: $spaceBetween;
			}

			&:nth-child(4n){
				margin-right: 0;
			}
		}
	}
}

@each $brand, $clr in $brand-clr {
	.brand--#{$brand} {
		.c-pool-list {
			&__intro{
				span{
					color: $clr;
				}
				hr{
					border-color: $clr;
				}
			}
			&__item{
				h3{
					background-color: $clr;
				}
			}
		}
	}
}