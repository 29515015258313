.timeline-component{
	&__gallery{
		display: none;
	}
}

.mfp-wrap{
	overflow-x: hidden;
}

.mfp-container{
	min-height: 100%;

	display: flex;
	align-items: center;

	.mfp-content{
		width: auto;
	}
}

.mfp-bg{
	background-color: $brand-navy-dark;
}

.mfp-window{
	//max-width: 1000px;
	//max-height: 800px;
	//margin: auto;
	//position: relative;

	//max-height: 60vh;
    //display: flex;
    //justify-content: center;
}
.mfp-figure{
	background-color: $brand-navy-dark;
	text-align: center;
	width: 100%;
	&:after{
		display: none;
	}
}

img.mfp-img {
	padding: 0px;
	max-height: 80vh;
    max-width: 90vw;
}

.mfp-close{
	position: absolute;
    top: -44px;
	right: 0;
	color: #fff;
	opacity: 1;

	&:active{
		top: -44px;
	}
}
.mfp-close-btn-in .mfp-close{
	color: #fff;
}

.mfp-bottom-bar{
	margin-top: 0px;
	color: #fff;
	display: flex;
	justify-content: space-between;
}

.mfp-counter{
	position: relative;
	font-size: 1rem;
}

.mfp-bottom-bar{
	padding-top: 1rem;

	@include media('>=0', '<medium') {
		display: flex;
		flex-direction: column;
		padding: 1rem;
	}
}

.mfp-title{
	color: $color-supernova;
}

.mfp-desc{
	padding-bottom: 2rem;
	font-size: 1.5rem;
	padding-left: 1rem;
}

button.mfp-arrow{
	//border-left: 10px solid #fff;
	//width: 10px;
	margin-top: 0px;

	width: 50%;
    height: 100%;
    top: 0px;
    bottom: 0px;
	transform: scale(1);
	position: absolute;

	&:before {
		border-right: none;
		margin: 0;
		display: none;
	}
	&:after {
		content: '';
		width: 10px;
		//border-left: 10px solid #fff;
		height: 110px;
		position: absolute;
		top: 50%;

		transform: translateY( calc(-50% - 25px));
		border-radius: 0px;
		border-width: 10px;
		border-right: 0px;
		margin-left: 0px;
		border-style: solid;
		margin-top: 0px;
		background-color: #fff;
	}
	&:focus {
		outline: none;
	}

	&:active{
		margin-top: 0px;
	}
}

button.mfp-arrow-left {
	left: -10px;
}

button.mfp-arrow-right {
	right: -10px;
	&:after {
		right: 0px;
	    left: auto;
	}
}

/* Following classes override the 'Magnific lightbox' default stylesheet */

/*
.mfp-container {
    display: flex;
    align-items: center;
}

.mfp-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mfp-figure {
    display: flex;
    align-items: center;
    margin: 5px;

    &:after {
        background: none;
        box-shadow: none;
    }
}

.mfp-close-btn-in .mfp-close {
    color: #ffffff;
}

.mfp-img {
    max-height: 90vh;
}

.mfp-bottom-bar {
    position: absolute;
    top: 95%;
    width: 100%;
    padding: 0 1em;

    div {
        color: #ffffff;
        line-height: normal;
        padding: 0;
        vertical-align: baseline;
    }
}

.mfp-title {
    font-size: 1.2em;
    font-weight: 700;
}

.mfp-counter {
    position: static;
}

.button.mfp-arrow {
    width: 0;
}

.button.mfp-arrow-left {
    &:active {
        outline: none;
    }
}

button.mfp-arrow-left {
    border-left: 10px solid #ffc700;
    &:before {
            border-right: none;
            margin: 0;
        }
    &:after {
            border-right: none;
            margin: 0;
        }
    &:focus {
        outline: none;
        }
}

button.mfp-arrow-right {
    border-right: 10px solid #ffc700;
    &:before {
            border-left: none;
            margin: 0;
        }
    &:after {
            border-left: none;
            margin: 0;
        }
    &:focus {
        outline: none;
        }
}

@media only screen and (min-width: 992px) {
    .mfp-img {
        min-width: 400px;
    }
}

*/