nav.megamenu {
	display: none;
	top: 100%;
	left: 0;

	&.active {
		display: block;
	}
}

nav.megamenu ul {
	@apply u-list-reset;
	font-family: $font-family-body;

	.title {
		@apply u-my-8 u-block u-relative;
		font-family: $font-family-heading;

		@include media('>=0', '<medium') {
			@apply u-my-5 u-flex u-justify-between u-items-center;
		}
	}

	a {
		font-weight: normal;
		text-transform: capitalize;
	}

	&.level-0 > li > a {
		font-family: $font-family-heading;
	}

	&.level-0 .category-link svg {
		@include media('>medium') {
			display: none;
		}
	}

	&.level-0 > li .dropdown {
		@include media('>=0', '<medium') {
			display: none;
		}
	}

	&.level-0 > li.expanded .dropdown {
		@include media('>=0', '<medium') {
			display: inherit;
		}
	}

	&.level-0 > li.expanded svg {
		transform: rotate(-180deg);
	}

	.level-1 {
		@apply u-flex-col;

		> li {
			@apply u-my-3;
		}
	}

	.col {
		@include media('>=0', '<medium') {
			.icon-minus {
				display: none;
			}
			&.active {
				.icon-minus {
					@apply u-block;
				}
				.icon-plus {
					display: none;
				}
			}
		}
		@include media('>medium') {
			.icon {
				display: none;
			}
		}
	}

	.col > .title:after {
		@apply u-border-yellow u-border-solid u-border-b u-absolute u-block;
		border-width: 1px;
		content: '';
		left: 0;
		bottom: -10px;
		width: 65px;
		transform: translateY(5px);
		opacity: 0;
		transition: $global-transition ease-in-out;
	}

	.col:hover > .title {
		&:after {
			transform: translateY(0);
			opacity: 1;
			transition: $global-transition ease-in-out;
		}
	}

	.level-1 {
		@include media('>=0', '<medium') {
			display: none;
		}
	}

	.col.active .level-1 {
		@apply u-flex;
	}
}

.breadcrumb-wrapper {
	top: 48px;
	@include media('<768px') {
		top: 0;
	}
	+ .sub-navigation {
		@apply u-mb-8;
		top: 0;
	}
}
