.playbyplay {
	&__logo {
		max-height: 75px;
	}

	&__timeline {
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			margin-left: -1px;
			top: 0;
			bottom: 0;
			width: 2px;
			background: $grey-light;
		}
	}

	&__event {
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			width: 0;
			height: 0;
		}
	}

	&__sub {
		 &:after {
				content: "";
				position: absolute;
				top: 50%;
				width: 0;
				height: 0;
				margin-top: -5px;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				left: 0;
			}
			&--in:after {
				border-left: 5px solid $champ-green;
			}
			&--out:after {
				border-right: 5px solid $brand-red;
			}
	}

	&__event, &__sub {
		&:before {
			margin-top: -4px;
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
		}
	}

	&__event {
		&--home:before {
			left: 100%;
			border-left: 4px solid $brand-navy;
		}
		&--away:before {
			right: 100%;
			border-right: 4px solid $brand-navy;
		}
		&--fullwidth {
		}
	}

	@include media('>medium') {
		&__tabs {
			/*&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				height: 1px;
				background: $grey-light;
			}*/
		}
		&__event {
			&:before {
				margin-top: -8px;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
			}
			&:after {
				margin-top: -5px;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
			}
		}
		&__event {
			&--home:before {
				border-left: 8px solid $brand-navy;
			}
			&--away:before {
				border-right: 8px solid $brand-navy;
			}

			&--home:after {
				left: 1.5rem;
				border-left: 5px solid $white;
			}
			&--away:after {
				right: 1.5rem;
				border-right: 5px solid $white;
			}
		}
	}

	@include media('>large') {
		&__event {
			min-width: 200px;
		}
	}
}

@media all and (-ms-high-contrast:none)
     {
     	*::-ms-backdrop, .ie-position{ margin-top: -3rem } /* IE11 */
     }
