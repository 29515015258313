.vdatetime {
    .vdatetime-popup {
        font-family: inherit!important;
    }

    .vdatetime-popup__actions {
        .vdatetime-popup__actions__button {
            border: 1px solid $grey-light;
        }

        & > * + * {
            margin-left: .5rem;
        }
    }
}