.c-nav-primary-header{
	@each $brand, $clr in $brand-clr {
		&.brand--#{$brand} {
			.c-nav__extra{
				border-color: $clr;
			}

			.c-nav__list__item.active{
				.menuItem:after{
					background-color: $clr;
				}
			}

			.menuSlab{
				border-color: $clr;

				.menuSlab__row > div:hover{
					.title{
						&:after{
							border-color: $clr;
						}
					}
				}

				.title{
					&:hover{
						&:after{
							border-color: $clr;
						}
					}
				}
			}
		}
	}

	background-color: $white;
	z-index: 100;

	&__inner{
		padding: 0 15px;
		background-color: $white;
	}
	&__container{
		position: relative;
		background-color: $white;
		z-index: 100;
		padding: 0px;
	}

	.c-nav{
		background-color: $white;
		display: flex;
		overflow: hidden;
		white-space: nowrap;

		.btn-wrapper{
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
		}

		&__list{
			list-style: none;
			display: flex;
			align-items: stretch;

			&__item{
				display: flex;

				&.active{
					background-color: $grey-faint;
				}
				.menuItem{
					padding: 1rem;
					@apply u-text-lg;
					&:after{
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: 2px;
					}
				}

				.icon{
					display: none;
				}
			}

			.invisible{
				visibility: hidden;
				display: none;
			}

			a{
				position: relative;
				white-space: nowrap;
			}
			a + a{
				margin-left: 1rem;
			}
		}

		&__extra{
			position: absolute;
			top: -100%;
			left: 0;
			width: 100%;
			transition: all ease-in-out 0.25s;
			z-index: -1;
			transform: translateY(-100%);
			background-color: $grey-faint;
			padding: 0;
			border-bottom: 2px solid;

			.menuItem{
				font-weight: bold;

				&:after{
					display: none;
				}
			}

			&.active{
				overflow: auto;
				max-height: calc(100vh - 3rem);
				transform: translateY(0);
				top: 100%;
			}

			.menuSlab{
				padding: 0rem;
				position: relative;
				display: none;
				top: 0;
				transform: none;
				border-bottom: 2px;

				.title{
					border: 0px;
				}

				&__row{
					display: flex;
					flex-direction: column;

					>div{
						width: 100%;
					}

					div + div{
						margin-top: 2rem;
						margin-left: 0rem;
					}
				}
			}

			.c-nav__list__item{
				flex-direction: column;
				white-space: normal;

				a{
					position: relative;
				}

				&.active{
					.menuSlab{
						position: relative;
						z-index: 1;
						display: block;
						transform: none;
						top: auto;
					}

					.icon-plus{
						display: none;
					}
				}

				.icon{
					pointer-events: none;
					display: block;
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translateY(-50%);
				}
			}
		}
	}

	.menuSlab{
		padding: 4rem;
		position: absolute;
		top: -100%;
		transform: translateY(-100%);
		background-color: $grey-faint;
		left: 0;
		right: 0;

		z-index: -1;
		transition-delay: 0.25s;
		transition: all ease-in-out 0.25s;
		border-bottom: 4px solid;

		.menuItem{
			font-weight: bold;
		}

		.title{
			display: inline-block;
			margin-bottom: 25px;
			font-weight: bold;
			position: relative;
			@apply u-text-lg;

			&:after{
				position: absolute;
				bottom: -15px;
				left: 0;
				display: block;
				content: '';
				border-width: 2px;
				width: 4rem;
				border-color: $white;
				transition: border-color ease-in-out 0.25s;
			}

			//border-bottom: 1px solid;
		}

		.level-1{
			padding: 0;
			list-style: none;
			li{
				@apply u-text-lg;
				margin-bottom: 3px;
			}
		}

		&.active{
			top: 100%;
			transform: translateY(0);
		}

		.container{
			pointer-events: auto;
		}

		&__row{
			display: flex;
			margin-bottom: 2rem;

			>div{
				width: 20%;
			}

			div + div{
				margin-left: 2rem;
			}
		}
	}
}

