.c-calendar-summary{
	@apply mt-4;
	//display: flex;
	//flex-direction: column;
	border-left: 8px solid transparent;

	&__wrapper {
		.c-calendar-summary {
			@include media('<medium') {
				display: none;

				&:nth-of-type(-n+4){
					display: block;
				}
			}
		}

		&.--is-expanded {
			.c-calendar-summary {
				display: block;
			}
		}
	}

	&__layout {
		display: flex;
		flex-direction: column;

		@include media('>=small') {
			display:-ms-grid;
			display: grid;
			-ms-grid-columns: 40% 60%;
			grid-template-columns: 40% 60%;
		}

		@include media('>=medium') {
			-ms-grid-columns: 50% 25% 25%;
			grid-template-columns: 50% 25% 25%;
		}

		@include media('>=large') {
			-ms-grid-columns: 40% 35% 25%;
			grid-template-columns: 40% 35% 25%;
		}
	}

	&__logo {
		@apply u-py-4 u-px-4;

		-ms-grid-row: 1;
		-ms-grid-column: 2;
		grid-column: 2;
		grid-row: 1;

		display: flex;
		align-items: center;
		justify-content: center;

		@include media('<small') {
			order: 1;
		}

		@include media('>=medium') {
			-ms-grid-row:1;
			-ms-grid-column:1;
			-ms-grid-row-span:2;
			grid-column: 1;
			grid-row: 1 / span 3;
			align-items: flex-start;
		}

		@include media('>=small', '<medium') {
			justify-content: flex-end;
		}

		@include media('>=medium') {
			@apply u-py-4 u-px-12;
			padding-top: 2rem;
			border-right: 2px solid $grey-light;
			justify-content: flex-start;
		}

		@include media('>=large') {
			grid-row: 1 / span 2;
		}
	}

	&__overview{
		@apply u-py-4 u-px-8 mt-4;

		-ms-grid-row: 1;
		-ms-grid-column: 1;
		grid-column: 1;
		grid-row: 1;

		@include media('<small') {
			order: 2;
		}

		@include media('>=medium') {
			-ms-grid-row: 1;
			-ms-grid-column: 2;
			grid-column: 2;
			grid-row: 1;
		}

		&__teams {
			@apply u-mb-8;
			display: flex;
			align-items: flex-start;

			& > * + * {
				@apply u-ml-3;
			}
		}

		&__logo {
			height: 35px;
			width: 35px;
			border: 2px solid $grey-light;
			border-radius: 100%;
		}

		&__match{
			font-weight: bold;
			font-size: 1.25rem;
			margin-right: 0.5em;
		}

		&__official-label {
			display: none;
			@include media('>=medium') {
				display: block;
			}
		}

		&__inline{
			display: block;

			@include media('>=huge') {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	&__location {
		@apply u-py-4 u-px-4 u-mt-4;

		-ms-grid-row:1;
		-ms-grid-column:3;
		grid-column: 3;
		grid-row: 1;

		@include media('<large') {
		}

		&__content {
			display: flex;
			align-items: center;

			& > * + * {
				margin-left: 1.25rem;
			}
		}

		&__pin {
			width: 1rem;
			height: 2rem;

			& > svg {
				width: 100%;
				height: 100%;
				fill: $grey-light;
			}
		}
	}

	&__detail {
		@apply u-pb-4 u-pl-4 u-pr-4 u-mb-4;

		-ms-grid-row:2;
		-ms-grid-column:1;
		-ms-grid-column-span:2;
		grid-column: 1 / span 2;
		grid-row: 2;

		@include media('<small') {
			order: 3;
		}

		@include media('>=small') {
			@apply u-pl-8;
			display: flex;
		}

		@include media('>=medium') {
			-ms-grid-row:2;
			-ms-grid-column:2;
			-ms-grid-column-span: 2;
			grid-column: 2 / span 2;
			grid-row: 2;
		}

		@include media('>=large') {
			-ms-grid-row:2;
			-ms-grid-column:2;
			-ms-grid-column-span:1;
			grid-column: 2;
			grid-row: 2;
		}

		&__column {
			flex-basis: 50%;

			& + & {
				margin-top: 1rem;

				@include media('>=small') {
					margin-top: 0;
					margin-left: 1rem;
				}
			}
		}
	}

	&__button {
		@apply u-pb-8 u-px-4;
		-ms-grid-row:1;
		-ms-grid-column:3;
		grid-column: 3;
		grid-row: 3;

		display: flex;
		align-items: center;

		@include media('<small') {
			order: 4;
		}

		@include media('>=small') {
			@apply u-pl-8;

			-ms-grid-row:3;
			-ms-grid-column:1;
			grid-column: 1;
			grid-row: 3;
		}

		@include media('>=medium') {
			@apply u-pl-8;
			-ms-grid-row: 3;
			-ms-grid-column: 2 / span 2;
			grid-column: 2 / span 2;
			grid-row: 3;
		}

		@include media('>=large') {
			@apply u-pl-4;

			-ms-grid-row:2;
			-ms-grid-column:3;
			grid-column: 3;
			grid-row: 2;

			display: block;
		}
	}

	&__loadmore {
        @include media('>=medium') {
            display: none;
		}

		&.--is-hidden {
			display: none;
		}
	}

	&__loadmoreBtn {
		display: flex;
		justify-content: center;
		margin: 5rem 0 2rem;
	}

	&__error {
		border-left: 10px solid $brand-red;
		background: $white;
		color: $black;
		padding: .5rem 1rem;
		margin: .5rem;
	}
}
