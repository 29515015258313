.text-container {
	h1, h2, h3, h4, h5, h6, p, ol, ul, hr {
		@apply u-block u-m-0 u-mb-5;
	}

	p > a {
		opacity: .4;

		&:hover {
			opacity: 1;
			text-decoration: underline;
			color: $brand-orange;
		}
	}

	hr {
		background: rgba(0,0,0, .2);
	}

	&--grey-text {
		color: $grey-light;
	}
}

.embed {
	.twitter-tweet {
		margin: 0 auto;
	}

	iframe {
		margin: 0 auto;
		display: block;
	}
}