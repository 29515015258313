.c-upcoming-competitions {
    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 10%;
        height: 100%;
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(244,244,244,0.9));
        z-index: 2;
    }

    &__slide {
        flex-shrink: unset;

        &.swiper-slide {
            // Strengthens specifity against ".swiper-container .swiper-slide"
            height: auto;
        }
    }

    &__event {
        height: 100%;
        align-items: stretch;
        background: $white;
        border-left: 6px solid $white;
        transition: 300ms cubic-bezier(.17,.91,.28,1.01);

        &:hover {
            // border hover colour is defined in the .brand-highlight--hover class.//
            // See mixin.scss for details //
            border-left: 6px solid;
        }
    }

    &__eventImageWrapper {
        align-items: center;
        display: flex;
        padding: 0.5em;
        width: 240px;
    }

    &__eventImageAsset {
        margin: 0 auto;
    }

    &__eventInfo {
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 140px;
        border-left: 1px solid $grey-faint;
        padding: 1em;

        & > * {
            color: $black;
        }
    }

    &__eventHeading {
        font-size: 1.275rem;
        word-wrap: break-word;

        @include media('>=medium') {
            word-wrap: normal;
        }
    }

    &__eventDates {
        font-size: 1.125rem;
        font-weight: normal;
    }

    &__eventText {
        font-weight: normal;
        font-size: 1rem;
    }

    &__eventDates {
        font-size: 1.125rem;
        font-weight: normal;
    }
}

.swiper-scrollbar {
    background: rgba($brand-blue, .1);
}

.swiper-scrollbar-drag {
    background: $brand-blue;
}