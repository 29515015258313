@import "~foundation-sites/scss/util/_unit.scss";

@function -zf-bp-serialize($map) {
	$str: '';
	@each $key, $value in $map {
		$str: $str + $key + '=' + -zf-bp-to-em($value) + '&';
	}
	$str: str-slice($str, 1, -2);

	@return $str;
}

.foundation-mq {
	font-family: "#{-zf-bp-serialize($grid-breakpoints)}";
}
