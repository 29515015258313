.primary-header {
	font-family: $font-family-heading;
	z-index: 100;
  }

  .primary-header.opened-menu {
	  @apply u-bg-navy u-text-white;
  }

  .primary-header__logo .icon-logo {
	  @apply u-w-8 u-h-8 u-mr-2;
  }

  // The actual button element is the wrapper for
  // the icon, so the hit areas is larger.
  .btn-wrapper {
	background: none;
	border: 0;
	cursor: pointer;
	height: 30px;
	padding: 0;
	position: relative;
	width: 30px;
	outline: 0;

	  &.active .btn-burger {
		  background: transparent;
		  border-color: transparent;
		  height: 100%;
		  margin: auto;
		  outline: 0;

		  &:before,
		  &:after {
			  background-color: $brand-orange;
			  transition: $global-transition;
		  }

		  &:after {
			  bottom: 0;
			  top: 0;
			  transform: rotate(45deg);
		  }

		  &:before {
			  bottom: 0;
			  top: 0;
			  transform: rotate(-45deg);
		  }
	  }
  }

  .btn-burger {
	background: rgba($brand-navy-dark,1);
	border: 1px solid rgba($brand-navy-dark,0);
	border-radius: 4px;
	bottom: 0;
	font-size: 1px;
	height: 4px;
	left: 0;
	margin: auto 0;
	position: absolute;
	right: 0;
	text-indent: -9999999px;
	top: 0;
	transition: .2s all;
	outline: 0;

	&:before,
	&:after {
	  background: $brand-navy-dark;
	  border-radius: 4px;
	  content: '';
	  height: 4px;
	  left: -1px;
	  margin: auto;
	  position: absolute;
	  transition: $global-transition;
	  width: calc(100% + 2px);
	}

	&:before {
	  top: -8px;
	}

	&:after {
	  bottom: -8px;
	}
  }