.btn--download {
	@apply u-border u-border-grey-light u-inline-block u-bg-transparent u-text-black rounded;

	&:hover {
		@apply u-bg-transparent u-text-yellow;
	}
}

.btn--link {
	@apply rounded u-bg-transparent u-text-black u-pl-0 u-pr-0;

	&:hover {
		@apply u-bg-transparent u-text-yellow;
		text-decoration: underline;
	}
}

.icon-folder {
	fill: transparent;
	stroke: $grey-light;
}

.disable-link {
	opacity: .2;
	pointer-events: none;
}
[data-lazy-load-list].is-loading {
	opacity: 0.4;
}

[data-lazy-load-list-content] > li {
	animation: fadeInFromBottom 0.3s linear;
	animation-fill-mode: both;
}

@keyframes fadeInFromBottom {
	0% {
		transform: translateY(20px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

[data-lazy-load-list].is-loading [data-lazy-load-list-action] {
	pointer-events: none;
}

