.schedule {
	&__date {
		transition: background-color .2s ease-in-out;
		will-change: background-color;
	}
}

/* Reduce lag in Safari */
.swiper-wrapper,
.swiper-slide{
   -moz-transform-style: flat;
   -webkit-transform-style: flat;
   -ms-transform-style: flat;
   transform-style: flat;
}
