.team-form {
	@include media('>=medium') {
		&__tabs {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				height: 1px;
				background: $grey-light;
			}
		}
	}
}
