.sidebar-component {
	margin-top: 15px;

	> div{
		//margin: 0 15px 15px !important;
		height: auto !important;

		padding: 0px !important;
		margin: 15px 15px 0px !important;
		width: 100% !important;
		max-width: 100% !important;
	}

	.article-listing--image-promo {
		height: 450px !important;
	}

	@include media('>=medium') {
		margin-left: 15px !important;

		> div{
			//margin: 0 15px 15px !important;
			height: auto !important;

			padding: 0 !important;
			margin: 15px 0 0 0 !important;
			width: calc(50% - 15px) !important;
			max-width: calc(50% - 15px) !important;
		}

		> div:nth-child(2n) {
			margin-left: 15px !important;
		}
	}

	@include media('>=large') {
		margin-top: 0;

		> div{
			width: 100% !important;
			max-width: 100% !important;
		}
		> div + div {
			margin-top: 15px !important;
		}

		> div:nth-child(2n) {
			margin-left: 0 !important;
		}
	}

	// undo all the important styles included from above
	&--sports {
		> div {
			margin: 15px 0 0 0 !important;
		}

		@include media('>=medium') {
			margin-left: 0 !important;

			> div {
				height: auto !important;

				padding: 0 !important;
				margin: 15px 7.5px 0 7.5px !important;
				width: calc((100% / 3) - 13px) !important;
				max-width: none !important;
			}

			> div:nth-child(2n) {
				margin-left: 0 !important;
			}
		}

		@include media('>=large') {
			margin-top: 0;

			> div{
				margin: 0 0 0 15px !important;
				width: 100% !important;
				max-width: 300px !important;
			}

			> div:nth-child(2n) {
				margin-left: 15px !important;
			}
		}
	}
}