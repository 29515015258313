.news-articles {
	.search-container {
		display: flex;
		justify-content: flex-end;
	}

	.c-article-listing-summary {
		.tag {
			border: 1px solid $grey-light;
			border-radius: 3px;
			padding: 4px 8px;
		}

		.c-article-listing-summary__summary{
			border-top: 0px solid transparent;
		}
		&.--is-active{
			.c-article-listing-summary__summary{
				border-top: 1px solid;
			}
		}
	}
}