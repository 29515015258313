@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	@include clearfix;
}

@mixin sticky {
	top: 0;
	z-index: 99;
	position: -webkit-sticky; /* Safari */
	position: sticky;
}

.sticky {
	@include sticky;
}

.sm\:sticky {
	@media (min-width: $bp-small){
		@include sticky;
	}
}

.md\:sticky {
	@media (min-width: $bp-medium){
		@include sticky;
	}
}

.lg\:sticky {
	@media (min-width: $bp-large){
		@include sticky;
	}
}

.z-index-150 {
	z-index: 150;
}

// See $brand-clr map in variables.scss for list of parent classes //
// The relevant parent class on a container will resolve the .brand-highlight class applied to any children //

@mixin short-border($color: #ffc700, $position: relative, $height: 3px) {
	position: $position;
	&::after{
		content: "";
		background-color: $color;
		position: absolute;
		height: $height;
		width: 50px;
		display: block;
		bottom: -2px;
	}

	&--middle {
		&::after {
			left: calc(50% - 25px);
		}
	}
}

.short-border {
	@include short-border;
}

@mixin branding {
	@each $brand, $clr in $brand-clr {
	  &--#{$brand} {
			.brand-highlight {
				color: $clr;
				border-color: $clr;

				&--hover:hover {
					border-color: $clr;
				}
			}

			a:hover {
				color: $clr;
				cursor: pointer;
			}

			a.is-active {
				color: $clr;
			}

			&.featured-players {
				.tabs {
					a {
						@apply u-bg-navy;
						color: $brand-blue;
						&.is-active {
							background-color: $clr;
						}
					}
				}
			}

			.brand-bg {
				background-color: $clr;
				color: $white;
				&:hover {
					color: $brand-navy;
					cursor: pointer;
				}
			}

			.btn--brand {
				background-color: $clr;
				color: white;
				&:hover {
					color: $brand-blue;
				}
			}

			.btn--brand-white {
				background-color: $clr;
				color: white;
				&:hover {
					background: white;
					color: $clr;
				}
			}

			.brand-bg-img-full{
				background-image: url(/assets/img/backgrounds/#{$brand}_full.png)
			}

			.brand-bg-img-half{
				background-image: url(/assets/img/backgrounds/#{$brand}_half.png)
			}

			.generic-bg-full {
				background-image: url(/assets/img/backgrounds/generic_full.jpg)
			}

			.brand-stroke {
				stroke: $clr !important;
			}

			.brand-border {
				border-color: $clr;

				&--grey {
					border-color: $grey-light;

					&:hover {
						border-color: $clr;
					}
				}
			}

			.short-border {
				@include short-border($clr);
			}

			.brand-color {
				color: $clr;
			}

			.brand-color-hover {
				&:hover {
					color: $clr !important;
				}
			}

			.brand-color-focus {
				&:focus {
					color: $clr !important;
				}
			}

			a.brand-color, button.brand-color {
				&:hover {
					color: $brand-blue;
				}
			}

			.brand-hover {
				color: $brand-blue;
				&:hover {
					color: $clr;
				}

				&--alt {
					color: $black;

					&:hover {
						color: $clr;
					}
				}
			}

			// Calendar datepicker overwrite
			.vdatetime-popup__header {
				background-color: $clr;
				color: $black;
			}
			.vdatetime-calendar__month__day--selected > span > span,
			.vdatetime-calendar__month__day--selected:hover > span > span {
				background-color: $clr;
				color: $black;
			}
			.vdatetime-popup__actions__button {
				color: $black;

				&:hover {
					color: $grey-dark;
				}
			}
			.vdatetime-year-picker__item--selected,
			.vdatetime-month-picker__item--selected {
				color: $clr;
			}

			// Article Listing Component Branding
			.article-listing-component,
			.c-article-listing-summary {
				a {
					&:hover {
						color: $clr;
					}
				}
			}

			@content;
		}
	}
}

.brand {
	@include branding;
}

@mixin t-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

@mixin ie {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	  @content;
	}
}
