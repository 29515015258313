.btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-family: $font-family-body;
  color: $white;
  background-color: $brand-navy-dark;
  border-radius: 40px;

  @include media('>=0', '<medium') {
    padding: 5px 15px;
  }

  @include media('>=medium') {
    min-width: 135px;
    padding: 5px 22px;
  }

  &:hover {
    font-weight: 800;
    background-color: $brand-red;

    .--beach &:not(.btn--reverse) {
      background-color: $beach-peach;
    }

    .--champ &:not(.btn--reverse) {
      background-color: $champ-green;
    }
		.--link &:not(.btn--reverse) {
			&:hover {
				text-decoration: underline;
			}
		}
  }

  &.btn--reverse {
    color: $brand-navy-dark;
    background-color: $white;
  }

  &.btn--ghost {
    background-color: transparent;
    border: 1px solid currentColor;
  }

  &.btn--ghost.btn--reverse {
    color: $white;
  }

	&.btn--link {
		min-width: 0;
	}
}

.btn-box {
  @apply u-inline-block u-border u-py-4 u-px-8 u-select-none u-text-center;

  &.btn-box--beach {
    background-color: $beach-peach;
    color: $grey-faint;
  }

  &----outline {
    @apply u-bg-navy u-text-white;

    &:hover,
    &:focus {
      @apply u-bg-navy--dark u-text-yellow;
    }
  }

  &--solid-white {
    @apply u-bg-white u-text-navy;

    &:hover,
    &:focus {
      @apply u-bg-white--dark u-text-navy;
    }
  }

  &--solid-white-to-red {
    @apply u-bg-white;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      @apply u-bg-red u-text-white;
    }
  }

  &--solid-white-to-blue {
    @apply u-bg-white;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      @apply u-bg-blue u-text-white;
    }
  }

  &--solid-blue-to-yellow-text {
    @apply u-bg-blue u-text-white;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      @apply u-bg-blue u-text-yellow;
    }
  }

  &--solid-blue-to-white {
    @apply u-bg-blue u-text-white;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      @apply u-bg-white u-text-blue--dark;
    }
  }

  &--solid-navy-to-white {
    @apply u-bg-navy u-text-white;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      @apply u-bg-white u-text-blue;
    }
  }

  &--solid-red {
    @apply u-bg-red u-text-white u-border-0;

    &:hover,
    &:focus {
      @apply u-bg-red--dark u-text-white;
    }
  }

  &--solid-green {
    @apply u-bg-green u-text-blue u-border-0;

    &:hover,
    &:focus {
      @apply u-bg-green--dark u-text-white;
    }
  }
}

button[disabled] {
	cursor: not-allowed;
}
