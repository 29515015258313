@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/*.scss";

// Breakpoints
$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-huge: 1400px;

$breakpoints: (
	small: 0,
	xs: 0,
	sm: $bp-small,
	md: $bp-medium,
	medium: $bp-medium,
	lg: $bp-large,
	xl: $bp-huge
);

$breakpoints: (
  'small': $bp-small,
  'medium': $bp-medium,
  'large': $bp-large,
  'huge': $bp-huge
);

// For Bootstrap.
$grid-breakpoints: (
	small: 0,
	xs: 0,
	sm: $bp-small,
	md: $bp-medium,
	medium: $bp-medium,
	lg: $bp-large,
	xl: $bp-huge
);

$container-max-width: 1320px;

// Colours
$black: #05001e;
$white: white;

$grey-faint: #f4f4f4;
$grey-light: #dadada;
$grey-dark: #707C83;

$brand-navy-dark: #00002d;
$brand-navy: #00002d;
$brand-blue: #0000ff;
$brand-red: #ff0000;
$brand-orange: #ffc700;
$snow-blue: #00FFFF;
$beach-peach: #ff9e6d;
$champ-green: #00ff00;
$color-supernova: #ffc700;
$color-beachroadtotokyo: rgb(0,75,155);
$color-eurosnowvolley: #00FFFF;
$color-superfinals: #00ff00;
$color-opencampus: #00002d;
$color-league-gold: rgb(183, 156, 65);
$color-league-silver: rgb(198, 198, 198);

$color-olympic: rgb(225, 14, 73);

$brand-clr: (
	general: $brand-blue,
	euro: $brand-red,
	beach: $beach-peach,
	snow: $snow-blue,
	indoor: $champ-green,
	cev: $color-supernova,
	olympic: $color-olympic,
	champ: $champ-green,
	leaguegold: $color-league-gold,
	leaguesilver: $color-league-silver,

	beachroadtotokyo: $color-beachroadtotokyo,

	superfinals: $color-superfinals,
	opencampus: $color-opencampus
);

$font-family-heading: 'LemurBold', Helvetica, sans-serif;
$font-family-body: 'Lemur', Helvetica, sans-serif;

$global-transition: all .3s;

$footer-logo-text-size: 24px;
