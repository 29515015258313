.c-calendar-search {
    display: flex;
    margin-bottom: 2.5rem;

    @include media('>=large') {
        justify-content: flex-end;
    }

    &__date {
        display: flex;
        text-align: center;
        width: 100%;
        flex-direction: column;

        @include media('>=small') {
            flex-direction: row;
        }

        @include media('>=large') {
            width: 70%;
        }

        @include media('>=huge') {
            width: 55%;
        }

        & > * + * {
            margin-top: 1rem;

            @include media('>=small') {
                margin-top: 0;
                margin-left: 1.5rem;
            }

            @include media('>=large') {
                margin-top: 0;
                margin-left: 3.5rem;
            }
        }
    }

    &__form {
			display: flex;
        .vdatetime {
					width: 100%;
				}
    }

    &__input {
        padding: .5rem;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.447 36.447"><path d="M30.224 3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75-1.474 0-2.67 1.233-2.67 2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75-1.473 0-2.67 1.233-2.67 2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75-1.473 0-2.67 1.233-2.67 2.75v1.197H6.224a4.255 4.255 0 00-4.25 4.25v24a4.255 4.255 0 004.25 4.25h24a4.255 4.255 0 004.25-4.25v-24a4.255 4.255 0 00-4.25-4.249zM25.286 2.75c0-.689.525-1.25 1.17-1.25.646 0 1.17.561 1.17 1.25v4.896c0 .689-.524 1.25-1.17 1.25-.645 0-1.17-.561-1.17-1.25V2.75zm-8.08 0c0-.689.525-1.25 1.17-1.25s1.17.561 1.17 1.25v4.896c0 .689-.525 1.25-1.17 1.25s-1.17-.561-1.17-1.25V2.75zm-8.081 0c0-.689.525-1.25 1.17-1.25s1.17.561 1.17 1.25v4.896c0 .689-.525 1.25-1.17 1.25s-1.17-.561-1.17-1.25V2.75zm22.849 29.448c0 .965-.785 1.75-1.75 1.75h-24c-.965 0-1.75-.785-1.75-1.75v-22h27.5v22z"/><path d="M6.724 14.626h4.595v4.089H6.724zM12.857 14.626h4.596v4.089h-4.596zM18.995 14.626h4.595v4.089h-4.595zM25.128 14.626h4.596v4.089h-4.596zM6.724 20.084h4.595v4.086H6.724zM12.857 20.084h4.596v4.086h-4.596zM18.995 20.084h4.595v4.086h-4.595zM25.128 20.084h4.596v4.086h-4.596zM6.724 25.54h4.595v4.086H6.724zM12.857 25.54h4.596v4.086h-4.596zM18.995 25.54h4.595v4.086h-4.595zM25.128 25.54h4.596v4.086h-4.596z"/></svg>');
        background-size: 22px 22px;
        background-position: 97% center;
        background-repeat: no-repeat;
        margin-right: .5rem;
    }

    &__select {
        text-align: center;

        @include media('>=small') {
            &:nth-of-type(1), &:nth-of-type(3) {
                flex-basis: 25%;

                @include media('>=large') {
                    flex-basis: 33.333%;
                }
            }

            &:nth-of-type(2) {
                flex-basis: 50%;

                @include media('>=large') {
                    flex-basis: 33.333%;
                }
            }
        }

        &__label {
            @include media('<large') {
                font-size: 1.15rem;
            }
        }

        &__option {
            background: $white;
            padding: .25rem .5rem;
            margin: .5rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > * + * {
                margin-left: .75rem;
            }
        }
    }

    &__arrow {
				border-color: inherit;
        border-radius: 100%;
				border-width: 2px;
				border-style: solid;
        display: block;
        position: relative;
        width: 26px;
        height: 26px;
        transition: border-color .2s ease;
				flex: 0 0 auto;

        &:hover > * {
					border-color: inherit;
        }

        &__prev, &__next {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            border-radius: 2px;
            width: 50%;
            height: 50%;
            border-style: solid;
        }

        &__prev {
            border-top-width: 2px;
            border-left-width: 2px;
            transform: translate(-35%, -50%) rotate(-45deg);
        }

        &__next {
            border-right-width: 2px;
            border-bottom-width: 2px;
            transform: translate(-65%, -50%) rotate(-45deg);
        }
    }
}
