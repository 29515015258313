.starting-team {
	&__players {
		columns: 2;
		column-gap: 2rem;
	}
	&__logo {
		img {
			max-width: 80px;
		}
	}
	&__player {
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	@media only screen and (min-width: 600px) {
		&__logo {
			order: 1;
		}
		&__coach {
			order: 4;
		}
		&__featured {
			order: 3;
		}
		&__players {
			order: 2;
		}
	}

	@media only screen and (min-width: 768px) {
		&__logo {
			order: 1;
		}
		&__coach {
			order: 2;
		}
		&__featured {
			order: 4;
		}
		&__players {
			order: 3;
		}
	}

	/*@include media('>large') {
		&__featured {
			order: 4;
		}
		&__players {
			order: 3;
		}
	}*/
}
