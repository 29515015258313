blockquote {
	font-family: $font-family-heading;

	p {
		margin: 0;
		line-height: 1;
		position: relative;
		display: inline;
	}
}

@include media('>=0', '<medium') {
	blockquote {
		&:before, &:after {
			@apply u-text-2xl;
			margin: 0;
		}
	}
}

.quote-box-wrapper {
	&--light .quote-box__inner {
		color: inherit;
		background-color: inherit;
		border: 1px solid $brand-orange;
	}
	&--dark .quote-box__inner {
		background-color: $grey-faint;
	}
}
