.icon {
	width: 20px;
	height: 20px;

	&--large {
		width: 32px;
		height: 32px;
	}

	&.icon-facebook {
		width: 10px;
		&--large {
			width: 20px;
		}
	}
	&.icon-youtube {
		width: 24px;
		&--large {
			width: 40px;
		}
	}
	&.icon-logo {
		width: 45px;
		height: 45px;
	}
	&.icon-phone{
		width: 16px;
		height: 16px;
	}
	&.icon-at-sign{
		width: 16px;
		height: 16px;

		fill: none;
		stroke: $black;
	}
	&.icon-website{
		width: 16px;
		height: 16px;

		fill: none;
		stroke: $black;

		&--large {
			width: 32px;
			height: 32px;

			fill: none;
			stroke: $black;
		}

		footer & {
			stroke: currentColor;
		}
	}
	&.icon-play-circle {
		fill: none;
	}
	&.icon-warning {
		fill: $yellow;
	}
	&.icon-penalty {
		fill: $red;
	}
	&.icon-expulsion,
	&.icon-disqualification {
		.cls-1 {
			fill: $yellow;
		}
		.cls-2 {
			fill: $red;
		}
	}
}

.feather-icon {
	stroke-width: 1px;
	fill: none;
	stroke: currentColor;
}

.flag-icon {
	position: relative;
	display: inline-block;
	float: left;
	width: 1.5em;
	height: 1.5em;
	margin-top: -3px;
	margin-right: 0.5em;
	background-color: #f4f4f4;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid $grey-light;
	border-radius: 50%;

	&--country {
		background-position: cover !important;
	}
}