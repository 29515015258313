.team-overview {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    padding: 1em;

    @include media('>=medium') {
        padding: 2em;
        width: 60%;
        margin: auto;
    }

    h3 {
        order: 2;
        flex-basis: 33%;
    }

    .team-1 {
        order: 1;
        flex-basis: 33%;
    }

    .team-2 {
        order: 3;
        flex-basis: 33%;
    }

    &__badge {
        width: 4em;
        height: 4em;
        border: 4px solid $grey-light;
        border-radius: 50%;
        object-fit: cover;

        .brand--champ & {
            border: none;
            border-radius: 0;
            object-fit: contain;
        }
    }
}

.stats-display-container {
    //width: 100%;
    //padding: 1em;

    //border-top: 2px solid $grey-faint;

    //@include media('>=medium') {
        //padding: 2em 3em;
    //}

	.tab-container{
		justify-content: center;
		padding-bottom: 1rem;
	}

    .chart-container {
		color: $white;
        width: 100%;
        margin: 0;
        margin-bottom: 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media('>=medium') {
            width: 90%;
            margin: auto;
            margin-bottom: 1em;
        }

        .bar {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            margin: 0;
            transition: width 1s;
        }

        .stat {
            width: 1.5em;
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
            z-index: 1;
        }

        .stat-m-l {
            margin-left: 20px;

            @include media('>=medium') {
                margin-left: 80px;
            }
        }

        .stat-m-r {
            margin-right: 20px;

            @include media('>=medium') {
                margin-right: 80px;
            }
        }

        .team-1-data {
            background-color: $brand-blue;
            display: flex;
            justify-content: flex-start;
        }

        .team-2-data {
            display: flex;
            justify-content: flex-end;
        }

        .bar-category {
            position: absolute;
        }
    }
}
