.socials {
    display: flex;
    justify-content: center;
    background-color: $white;
  }

  .socials__item {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $brand-navy-dark;
    border-radius: 50%;

    img {
      width: 100%;
    }

    &--white {
      background-color: $white;
    }

    @include media('>=0', '<medium') {
      width: 36px;
      height: 36px;
      margin: 0 5px;
    }

    @include media('>=medium') {
      width: 54px;
      height: 54px;
      margin: 0 10px;
    }

    &:hover {
      .--beach & {
        background-color: $beach-peach;
      }

      .--champ & {
        background-color: $champ-green;
      }
    }

    svg {
      fill: currentColor;

      @include media('>=0', '<medium') {
        transform: scale(1.3);
      }

      @include media('>=medium') {
        transform: scale(2);
      }
    }
  }