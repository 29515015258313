//Brandcolors for different Modules
.tabs.brand--beach {
	.is-active {
		color: $beach-peach;
		a {
			border-color: $beach-peach;
		}
	}
	a {
		&:hover {
			color: $beach-peach;
		}
		&:focus {
			color: $beach-peach;
		}
	}
}

.tabs.brand--euro {
	.is-active {
		color: $brand-red;
		a {
			border-color: $brand-red;
		}
	}
	a {
		&:hover {
			color: $brand-red;
		}
		&:focus {
			color: $brand-red;
		}
	}
}

.tabs.brand--snow {
	.is-active {
		color: $snow-blue;
		a {
			border-color: $snow-blue;
		}
	}
	a {
		&:hover {
			color: $snow-blue;
		}
		&:focus {
			color: $snow-blue;
		}
	}
}

.tabs.brand--cev {
	.is-active {
		color: $color-supernova;
		a {
			border-color: $color-supernova;
		}
	}
	a {
		&:hover {
			color: $color-supernova;
		}
		&:focus {
			color: $color-supernova;
		}
	}
}

.tabs.brand--olympic {
	.is-active {
		color: $color-olympic;
		a {
			border-color: $color-olympic;
		}
	}
	a {
		&:hover {
			color: $color-olympic;
		}
		&:focus {
			color: $color-olympic;
		}
	}
}

.tabs.brand--champ {
	.is-active {
		color: $champ-green;
		a {
			border-color: $champ-green;
		}
	}
	a {
		&:hover {
			color: $champ-green;
		}
		&:focus {
			color: $champ-green;
		}
	}
}

.tabs.brand--beachroadtotokyo {
	.is-active {
		color: $color-beachroadtotokyo;
		a {
			border-color: $color-beachroadtotokyo;
		}
	}
	a {
		&:hover {
			color: $color-beachroadtotokyo;
		}
		&:focus {
			color: $color-beachroadtotokyo;
		}
	}
}

.tabs.brand--superfinals {
	.is-active {
		color: $color-superfinals;
		a {
			border-color: $color-superfinals;
		}
	}
	a {
		&:hover {
			color: $color-superfinals;
		}
		&:focus {
			color: $color-superfinals;
		}
	}
}

.tabs.brand--opencampus {
	.is-active {
		color: $color-opencampus;
		a {
			border-color: $color-opencampus;
		}
	}
	a {
		&:hover {
			color: $color-opencampus;
		}
		&:focus {
			color: $color-opencampus;
		}
	}
}

.tabs.brand--general {
	.is-active {
		color: $brand-blue;
		a {
			border-color: $brand-blue;
		}
	}
	a {
		&:hover {
			color: $brand-blue;
		}
		&:focus {
			color: $brand-blue;
		}
	}
}

.tabs.brand--indoor {
	.is-active {
		color: $champ-green;
		a {
			border-color: $champ-green;
		}
	}
	a {
		&:hover {
			color: $champ-green;
		}
		&:focus {
			color: $champ-green;
		}
	}
}
