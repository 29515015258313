.c-scroll-summary{
	@apply u-py-4 u-px-4;
	display: flex;
    justify-content: space-between;
	flex-direction: column;
	border-top: 4px solid transparent;

	@include media('>=small') {
		@apply u-py-4 u-px-5;
	}

	&__status {
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	&__teamSummary{
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;

		.img{
			background-size: 152%;
			background-repeat: no-repeat;
			display: block;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-position: 50% 50%;
			border: 2px solid $grey-light;
			margin-bottom: 1rem;
		}
	}

	&__teamFlag {
		width: auto;
		max-height: 40px;
		margin-bottom: .5rem;
	}

	&__scorecentre{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		& > * + * {
			margin-left: .75rem;
		}
	}

	&__teamId {
		font-weight: bold;
		font-size: 20px;
		text-transform: uppercase;

		@include media('>=medium') {
			font-size: 20px;
		}
	}

	&__teamLabel {
		line-height: 1.4;
		color: $brand-blue;
	}

	&__score {
		font-size: 2rem;
		text-align: center;
		margin-top: .5rem;
		letter-spacing: 1px;
	}

	&__gender {
		top: .75rem;
		right: 1rem;
	}
}
