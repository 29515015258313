@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy-Light.otf") format("truetype"),
    url("/assets/fonts/Gilroy-Light.ttf") format("truetype"),
    url("/assets/fonts/Gilroy-Light.woff2") format("woff2"),
    url("/assets/fonts/Gilroy-Light.woff") format("woff");
    font-weight: normal;
	  font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url("/assets/fonts/Gilroy-ExtraBold.otf") format("truetype"),
      url("/assets/fonts/Gilroy-ExtraBold.ttf") format("truetype"),
      url("/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"),
      url("/assets/fonts/Gilroy-ExtraBold.woff") format("woff");
      font-weight: 700;
	    font-style: normal;
  }

  @font-face {
    font-family: "GilroyBold";
    src: url("/assets/fonts/Gilroy-ExtraBold.otf") format("opentype"),
      url("/assets/fonts/Gilroy-ExtraBold.ttf") format("truetype"),
      url("/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"),
      url("/assets/fonts/Gilroy-ExtraBold.woff") format("woff");
      font-weight: normal;
	    font-style: normal;
  }

@font-face {
  font-family: "Lemur";
  src: url("/assets/fonts/Lemur-Regular.ttf") format("opentype"),
  url("/assets/fonts/Lemur-Regular.ttf") format("truetype"),
  url("/assets/fonts/Lemur-Regular.woff2") format("woff2"),
  url("/assets/fonts/Lemur-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
	font-family: "Lemur";
	src: url("/assets/fonts/Lemur-Bold.otf") format("truetype"),
	url("/assets/fonts/Lemur-Bold.ttf") format("truetype"),
	url("/assets/fonts/Lemur-Bold.woff2") format("woff2"),
	url("/assets/fonts/Lemur-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face {
  font-family: "LemurBold";
  src: url("/assets/fonts/Lemur-Bold.otf") format("truetype"),
  url("/assets/fonts/Lemur-Bold.ttf") format("truetype"),
  url("/assets/fonts/Lemur-Bold.woff2") format("woff2"),
  url("/assets/fonts/Lemur-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-weight: normal;
}

h1 {
}

h2 {
  line-height: 1.1;
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-family: $font-family-body;
  font-weight: normal;
	color: $brand-navy-dark;
}

p {
  font-size: 1.375rem;
  line-height: 1.3em;
  color: inherit;
}

hr {
  margin: 50px 0 40px;
  border: 0;
  border-bottom: 1px solid rgba($white, 0.5);
}

.centered-heading {
  text-align: center;
  line-height: 1.2;
  margin: 37px 0;

 &--no-margin {
   margin:0;
 }

  @include media('>=0', '<medium') {
    font-size: 2.5rem;
  }

  @include media('>=medium') {
    font-size: 3.5rem;
  }

  a {
    font-size: 2rem;
    color: currentColor;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      color: $brand-orange;
    }
  }
}

a {
  color: currentColor;
  text-decoration: none;

  &:hover {
    color: $brand-orange;
    transition: $global-transition;
  }
}

.rte-lemur-heading {
  @apply u-font-lemur u-text-5xl u-font-bold;
}

.rte-lemur-subheading{
  @apply u-font-lemur u-text-2xl u-mb-4 u-font-bold;
}

.rte-lemur-p {
  @apply u-font-lemur;
}

.rte-gilroy-emphasis {
  @apply u-font-bold u-font-gilroy u-text-xl;
}

.rte-gilroy-text {
  @apply u-font-gilroy u-text-lg;
}

.rte-gilroy-footnote {
  @apply u-font-gilroy u-text-xs;
}