.competition-lightbox {
    .box {
        cursor: pointer;
        transition: all .3s;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }

        &__title {
            min-height: 3em;
        }
    }
}

.mfp-content {
    span.mfp-desc {
        font-size: 1rem;
        padding-left: 0;
    }
}