.tabs-panel {
	display: none;

	&.is-active {
		display: block;
	}
}

.tabs-title {
	a {
		@apply u-text-center u-py-4 u-px-4 u-block u-text-lg u-select-none u-border-b-4 u-border-transparent;

		@screen md {
			@apply u-py-10 u-px-8 u-truncate;
		}

		&:hover,
		&:focus {
			@apply u-text-blue--dark u-border-grey-light;
		}
	}

	&.is-active {
		@apply u-text-blue;

		a {
			@apply u-border-b-4 u-border-blue;

			&:hover,
			&:focus {
				@apply u-border-blue--dark;
			}
		}
	}
}

.official-tabs {
	.is-active {
		@apply u-bg-blue u-text-white;
	}
}