%hide-mobile {
	display: none;

	@include media('>=medium') {
		display: inherit;
	}
}

%hide-desktop {
	display: inherit;

	@include media('>=medium') {
		display: none;
	}
}

%pop-out-full-width {
	width: 99.58vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

%pop-out-full-width-from-two-column {
	width: 99.4vw;
	margin-left: calc(17% - 50vw);
	margin-right: calc(50% - 50vw);

	@media (max-width: 1023px) {
		width: 100vw;
		margin-left: calc(0% - 50vw) !important;
	}
}

.hide-mobile {
	@extend %hide-mobile;
}

.hide-desktop {
	@extend %hide-desktop;
}

%sr-only {
    @include t-hidden;
}

.sr-only {
	@extend %sr-only;
}