.match-info {
	.accordion-title, .tabs {
		@apply u-bg-white;
	}
	.accordion-content, .tabs-content {
		@apply u-relative u-bg-navy;
		h2, h3, h4, h5 {
			@apply u-mb-3;
		}
	}
	.accordion-content {
		@apply u-hidden;

		&__image {
			padding-top: 110%;
			background-position: top center;
			background-size: cover;
		}
	}

	// Accordions
	.accordion-title {
		@apply u-block u-p-4 u-border-b u-border-grey-light;
		&:hover {
			@apply u-text-red;
		}
	}

	// Tabs
	.tabs-content {
		width: 60%;
	}
	.tabs {
		width: 40%;
	}
	.tabs-title {
		a {
			@apply u-border-b u-border-grey-light u-text-left u-px-8 u-py-10;
			&:focus {
				@apply u-text-black;
			}
		}

		&.is-active {
			a {
				@apply u-bg-wild-sand u-border-grey-light--dark;
			}
		}
	}

	&__logo {
		bottom: 0;
		right: 0;
		max-width: 150px;
	}

	&__cta {
		bottom: 0;
	}
}
