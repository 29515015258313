.c-image-promo-exapnd{
	$root: &;
	position: relative;

	&__row{
		display: flex;
		flex-direction: column;

		@include media('>=medium') {
			flex-direction: row;
		}

		&:hover{
			#{$root}__item {
				&__content{
					div{
						h2{ opacity: 1;}
					}
				}
			}
		}
	}

	&__item{
		position: relative;
		background-size: cover;
		background-position: 50% 50%;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		transition: flex .6s cubic-bezier(.23,1,.32,1);
		will-change: flex;
		backface-visibility: hidden;
		box-sizing: border-box;

		&:hover{
			flex-grow: 2 !important;

			#{$root}__item {
				&__content{
					background: rgba($brand-navy-dark, 0.75);
					div{
						> * {
							transition: opacity 0.25s ease-in-out;
							opacity: 1;
						}
					}
				}
			}
		}

		&__content{
			background: rgba($brand-navy-dark, 0);
			transition: background-color 0.25s ease-in-out;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			overflow: hidden;

			> div{
				padding: 2rem;
				max-width: 100%;
				color: #fff;

				> * {
					transition: opacity 0.25s ease-in-out;
					opacity: 0;
				}
			}
		}
	}

	@each $brand, $clr in $brand-clr {
		&.brand--#{$brand} {
			hr{
				border-color: $clr;
			}
			a:hover{
				color: $clr;
			}
			.label{
				color: $clr;
			}
		}
	}
}