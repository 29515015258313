
@import 'layout/include-media'; //from node_modules

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@import 'base/variables';
@import 'base/mixin';
@import 'base/typography';
@import 'base/extends';
@import 'base/icons';
@import 'base/animation';
@import 'base/foundation';
@import 'base/forms';
@import 'base/tables';

@import "~swiper/dist/css/swiper.min.css";
@import "~video.js/dist/video-js.css";
@import "~magnific-popup/dist/magnific-popup.css";

@import 'layout/layout';
@import 'modules/buttons';

@import 'modules/header';
@import 'modules/footer';
@import 'modules/breadcrumbs';
@import 'modules/navigation';
@import 'modules/_c-nav';
@import 'modules/article';
@import 'modules/carousel';
@import 'modules/hero-carousel';
@import 'modules/timeline-carousel';
@import 'modules/timeline-lightbox';
@import 'modules/blockquote';
@import 'modules/doc-list';
@import 'modules/columns';
@import 'modules/video-block';
@import 'modules/rich-text-block';
@import 'modules/article-listing';
@import 'modules/content-cards';
@import 'modules/scorecentre';
@import 'modules/tabs';
@import 'modules/event-promo';
@import 'modules/full-width-cta';
@import 'modules/shirt';
@import 'modules/schedule';
@import 'modules/socials';
@import 'modules/sponsors-list';
@import 'modules/selector';
@import 'modules/sidebar';
@import 'modules/starting-team';
@import 'modules/address-card';
@import 'modules/playbyplay';
@import 'modules/pool-standings';
@import 'modules/match-summary';
@import 'modules/final-event-rankings';
@import 'modules/player-stats';
@import 'modules/team-stats-bar';
@import 'modules/match-gallery';
@import 'modules/squad-listings';
@import 'modules/match-report';
@import 'modules/team-form';
@import 'modules/top-scorer-card';
@import 'modules/match-poll';
@import 'modules/match-info';
@import 'modules/double-donut';
@import 'modules/stats-wheel';
@import 'modules/c-image-promo-expand';
@import 'modules/c-pool-list';
@import 'modules/team-listing';
@import 'modules/upcoming-competitions';
@import 'modules/pagination';
@import 'modules/ranking-list';
@import 'modules/scroll-summary';
@import 'modules/search';
@import 'modules/news-articles';
@import 'modules/competition-gallery';
@import 'modules/vdatetime-theme';
@import 'modules/calendar';
@import 'modules/calendar-search';
@import 'modules/calendar-summary';
@import 'modules/calendar-sports-summary';
@import 'modules/_competition-components.scss';
@import 'modules/_star-player.scss';
@import 'modules/_team-profile.scss';
@import 'modules/manual-event';
@import 'modules/leader-list';
@import 'modules/brandcolor.scss';
@import 'modules/statistics.scss';
@import 'modules/game-hub.scss';

// roundel element added conditonally if captain = true //
.roundel {
    position: absolute;
    padding: 5px;
    text-align: center;
    z-index: 9;
}

.hidden {
  display: none !important;
}

//WIP
/*.flexible-richtext-component > .row > * + * {
	margin-top: 2rem !important;
}*/
