.match-report {
    > div {
        padding: 0 8rem 0 8rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 2rem;
        font-weight: 700;
    }

    &__summary-container {
        position: relative;
        overflow: hidden;
        height: auto;
        max-height: 200px;

        &--show-overflow {
            overflow: visible;
            height: auto;
            max-height: none;
        }
    }
}

.gradient-overlay {
    position: absolute;
    display: block;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: transparent;
    z-index: 9;

    &--white {
        background-image: linear-gradient(to top, $white, rgba(255, 255, 255, 0));
    }
}

.toggle-show-hide-btn {
    display: block;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 2rem;
}

.is-active {
    display: block;
}

.is-hidden {
    display: none;
}

/*@keyframes expand {
    0% {
        height: 10rem;
    }
    100% {
        overflow: visible;
        height: auto;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}*/
