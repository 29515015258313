.team-container {
    width: 100%;
    margin-top: 1em;

    .squad-container {
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        pointer-events:none;

        &__player-card {
            position: relative;
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: $white;
            margin: 0.75rem;

            @media only screen and (min-width: 600px) {
                flex-basis: 46%;
                max-width: 46%;
                margin: 0.5rem;
            }

            @include media('>=medium') {
                flex-basis: 31%;
                max-width: 31%;
                margin: 0.5rem;
            }

            @include media('>=large') {
                flex-basis: calc(33.3% - 1rem);
            }

            @media only screen and (min-width: 1200px) {
                flex-basis: calc(25% - 1rem);
                margin: 0.5rem;
            }

            &__number {
                position: absolute;
                background-color: rgba(0, 0, 45, 0.5);
                color: $white;
                font-size: 1.5rem;
                font-weight: 700;
                padding: 0.5rem;
            }

            &__summary {
                max-height: 110px;
            }

            &__captain {
                position: absolute;
                top: -7px;
                right: -7px;
                height: 30px;
                width: 30px;
                background-color: $brand-red;
                color: $white;
                font-size: 1rem;
                padding-bottom: 1rem;
                border-radius: 50%;
            }

            .content-card__image {
				background-position: 50% 0%;
				padding-top: 130%;
            }
        }
    }
}

@each $brand, $clr in $brand-clr {
	.brand--#{$brand} {
		.c-article-listing-summary{
			&__summary{
				border-top: 1px solid #fff;
			}

			&.--is-active{
				.c-article-listing-summary__summary{
					border-top: 1px solid $clr;
				}
			}
		}
	}
}

